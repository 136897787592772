import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './authentication/components/login/login.component';
import { BaseModule } from './base/base.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from './authentication/services/authentication.service';
import { ForgotPasswordComponent } from './authentication/components/forgot-password/forgot-password.component';
import { ToastrModule } from 'ngx-toastr';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { ErrorHandlingInterceptor } from './common/services/error-handler-interceptor';
import { AuthenticationInterceptor } from './common/services/authenticion-interceptor';
import { ResortComponent } from './solay/components/resort/resort.component';
import { UserService } from './authentication/services/user.service';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MapEditModalComponent } from './solay/map-edit-modal/map-edit-modal.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ResortListComponent } from './solay/components/resort-list/resort-list.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TermAndConditionComponent } from './solay/components/term-and-condition/term-and-condition.component';
import { NonAdminComponent } from './solay/components/non-admin/non-admin.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { GuestBookingsComponent } from './solay/components/guest-bookings/guest-bookings.component';
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { ResortAreaEditorWrapper } from './solay/components/resort-area-editor/resort-area-editor.wrapper';
import { DynamicFormComponent } from './solay/components/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './solay/components/dynamic-form/dynamic-form-input.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResortComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    MapEditModalComponent,
    ResortListComponent,
    TermAndConditionComponent,
    NonAdminComponent,
    GuestBookingsComponent,
    ResortAreaEditorWrapper,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    BaseModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    CanvasWhiteboardModule,
    NgbModule,
    NgxMaskModule.forRoot(options),
    TooltipModule.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    AccordionModule.forRoot(),
    TimezonePickerModule,
  ],
  entryComponents: [MapEditModalComponent],
  providers: [
    AuthenticationService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
