import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { InputBase } from './input-base';

@Component({
  selector: 'app-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.scss'],
})
export class DynamicFormQuestionComponent {
  @Input() input: InputBase<any>;
  @Input() form: FormGroup;
  showImage = true;

  get isValid() {
    return typeof this.form.controls[this.input.key] !== 'undefined' ? this.form.controls[this.input.key].valid : false;
  }

  onChange(event) {
    // if there is a file set the first one to the respective form
    if (this.input.type === 'file' && event.target.files && event.target.files.length > 0) {
      // hide the image since the src is now outdated
      this.showImage = false;
      this.form.get(this.input.key).setValue(event.target.files[0]);
    }
  }
}
