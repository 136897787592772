import * as React from 'react';
import { useState, useEffect } from 'react';

import { ResortObject, ResortArea } from '~common/model';
import { ResortAreaEditor } from '~components/resort-area-editor/resort-area-editor';

type Props = {
  resort: ResortObject;
};

export const ResortAreaEditorLoader = ({ resort }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialResortAreas, setInitialResortAreas] = useState([]);

  useEffect(() => {
    if (resort && resort.beaches && resort.pools) {
      let draftResortAreas = [];

      resort.beaches.forEach((beach: any) => {
        draftResortAreas = draftResortAreas.concat([
          {
            id: beach.id,
            type: 'beach',
            name: beach.name || '',
            description: beach.description || '',
            latitude: parseFloat(beach.latitude || '0'),
            longitude: parseFloat(beach.longitude || '0'),
            layout: beach.layout || '/assets/img/resort-area-layout-example.png',
            scale: beach.scale || 10,
            items: beach.items || [],
            attachments: beach.attachments || [],
          },
        ]);
      });

      resort.pools.forEach((pool: any) => {
        draftResortAreas = draftResortAreas.concat([
          {
            id: pool.id,
            type: 'pool',
            name: pool.name || '',
            description: pool.description || '',
            latitude: parseFloat(pool.latitude || '0'),
            longitude: parseFloat(pool.longitude || '0'),
            layout: pool.layout || '/assets/img/resort-area-layout-example.png',
            scale: pool.scale || 10,
            items: pool.items || [],
            attachments: pool.attachments || [],
          },
        ]);
      });

      setInitialResortAreas(draftResortAreas);
      setIsLoading(false);
    }
  }, [resort]);

  return !isLoading ? (
    <ResortAreaEditor initialResortAreas={initialResortAreas} />
  ) : (
    <div className="prominent-text">Loading...</div>
  );
};
