import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import cx from 'classnames';
import produce from 'immer';
import { useForm, useFieldArray } from 'react-hook-form';

import { add, remove, save } from '~components/resort-area-editor/resort-area-editor.icons';
import { capitalize } from '~utilities';

type Props = {
  type: string;
  attachments: any | any[];
  discardedAttachments: number[];
  setDiscardedAttachments: Dispatch<SetStateAction<number[]>>;
  setAttachmentsFiles: Dispatch<SetStateAction<any[]>>;
};

export const ResortAreaEditorGallery = ({
  type,
  attachments,
  discardedAttachments,
  setDiscardedAttachments,
  setAttachmentsFiles,
}: Props) => {
  const { control, register, handleSubmit } = useForm();

  const { fields, append } = useFieldArray({
    control,
    name: 'imageFiles',
  });

  const handleAttachmentClick = (id: number) => {
    if (!discardedAttachments.includes(id)) {
      setDiscardedAttachments(
        produce(discardedAttachments, (draft: any) => {
          draft.push(id);
        }),
      );
    } else {
      setDiscardedAttachments(
        produce(discardedAttachments, (draft: any) => {
          return draft.filter((attachmentId: number) => attachmentId !== id);
        }),
      );
    }
  };

  const onSubmit = (data: any) => {
    const { imageFiles } = data;

    let newAttachmentsFiles = [];
    imageFiles.forEach((imageFile: any) => {
      newAttachmentsFiles = newAttachmentsFiles.concat([imageFile.file[0]]);
    });

    setAttachmentsFiles(newAttachmentsFiles);
  };

  return (
    <div className="col form-group">
      <label className="custom-label d-block">{capitalize(type)} photos</label>
      <div className="resort-area-gallery">
        <div>
          <div className="resort-area-gallery-preview">
            {attachments.map((attachment: any, index: number) => (
              <section key={index}>
                <div>
                  <span>
                    <button
                      type="button"
                      onClick={() => handleAttachmentClick(attachment.id)}
                      className="button"
                    >
                      {discardedAttachments.includes(attachment.id) ? (
                        <>{add()} Restore photo</>
                      ) : (
                        <>{remove()} Remove photo</>
                      )}
                    </button>
                  </span>
                  <img
                    src={attachment.path}
                    className={cx(discardedAttachments.includes(attachment.id) && 'discarded')}
                    alt=""
                  />
                </div>
              </section>
            ))}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className=" mt-3">
          <div>Add and save new photos. <strong>Note</strong>: Photos larger than 2MB will not save properly.</div>
          <ul>
            {fields.map((item: any, index: number) => (
              <li key={item.id}>
                <input
                  type="file"
                  name={`imageFiles[${index}].file`}
                  defaultValue={item.value}
                  ref={register()}
                />
              </li>
            ))}
          </ul>
          <div className="d-flex mt-3">
            <button type="button" onClick={() => append({ file: '' })} className="button">
              {add()} Add new photo
            </button>
            <button type="submit" className="button ml-3">
              {save()} Save new photos
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
