import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
    constructor(private tosterService: ToastrService) { }
    handleError(error: HttpErrorResponse) {
        // this.tosterService.error('Error');
        return throwError(error);
    }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req)
            .pipe(catchError(this.handleError));
    }

}
