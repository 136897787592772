import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../../common/model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  private loggedIn = new BehaviorSubject<boolean>(false);
  private superAdmin = new BehaviorSubject<boolean>(false);
  private staffUser = new BehaviorSubject<boolean>(false);
  loginEndpoint = `${environment.endpoint}/login`;
  forgotPasswordEndpoint = `${environment.endpoint}/profile/resetpass`;


  showProfileEndpoint = `${environment.endpoint}/profile/show`;

  registerEndpoint = `${environment.endpoint}/resort-admin/register`;


  constructor(private http: HttpClient) { }
  login(body): Observable<User> {
    return this.http.post<User>(this.loginEndpoint, body);
  }
  resetPassword(body): Observable<User> {
    return this.http.post<User>(this.forgotPasswordEndpoint, body);
  }
  register(body): Observable<User> {
    return this.http.post<User>(this.registerEndpoint, body);
  }
  getUser(): Observable<any> {
    return this.http.get(this.showProfileEndpoint);
  }
  getTokenId() {
    return localStorage.getItem('token-id');
  }
  setTokenId(token) {
    localStorage.setItem('token-id', token);
  }
  clearTokenId() {
    localStorage.removeItem('token-id');
  }
  setLogin(value: boolean) {
    this.loggedIn.next(value);
  }
  setSuperAdmin(value: boolean) {

    // return this.superAdmin.asObservable()
    this.superAdmin.next(value);
  }
  setStaffUser(value: boolean) {
    this.staffUser.next(value);
  }
  get isSuperadmin() {
    return this.superAdmin.asObservable();
  }
  get isStaffUser() {
    return this.staffUser.asObservable();
  }
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
}
