import * as React from 'react';
import { useEffect } from 'react';
import produce from 'immer';

import { ResortArea } from '~common/model';
import { useStore } from '~components/resort-area-editor/resort-area-editor.store';
import { ResortAreaEditorForm } from '~components/resort-area-editor/resort-area-editor.form';
import { add } from '~components/resort-area-editor/resort-area-editor.icons';

type Props = {
  initialResortAreas: ResortArea[];
};

export const ResortAreaEditor = ({ initialResortAreas }: Props) => {
  const resortAreas = useStore((state) => state.resortAreas);
  const setResortAreas = useStore((state) => state.setResortAreas);

  useEffect(() => {
    setResortAreas(initialResortAreas);
  }, []);

  const createBeach = () => {
    setResortAreas(
      produce(resortAreas, (draft: any) => {
        draft.push({
          id: undefined,
          type: 'beach',
          name: 'Default beach title',
          description: '',
          latitude: 0,
          longitude: 0,
          layout: '/assets/img/placeholder.png',
          scale: 10,
          items: [],
          attachments: [],
          discardedAttachments: undefined,
          attachmentsFiles: undefined,
        });
      }),
    );
  };

  const createPool = () => {
    setResortAreas(
      produce(resortAreas, (draft: any) => {
        draft.push({
          id: undefined,
          type: 'pool',
          name: 'Default pool title',
          description: '',
          latitude: 0,
          longitude: 0,
          layout: '/assets/img/placeholder.png',
          scale: 10,
          items: [],
          attachments: [],
          discardedAttachments: undefined,
          attachmentsFiles: undefined,
        });
      }),
    );
  };

  return (
    <>
      <div className="custom-label">Resort areas editor</div>
      <div className="mb-3">
        Click <b>"Create new beach"</b> or <b>"Create new pool"</b> below to add a new resort area.
        Once you are finished making changes to a resort area, click <b>"Save"</b> on each edited
        resort area. Once all other details are ready, click <b>"Finish resort setup"</b> at the
        very bottom of the page to permanently save the changes.
      </div>
      {resortAreas.map((area: ResortArea, index) => (
        <ResortAreaEditorForm
          key={index}
          id={area.id}
          startOpen={index >= initialResortAreas.length}
        />
      ))}
      <div className="d-flex mt-3">
        <button onClick={createBeach} className="button">
          {add()} Create new beach
        </button>
        <button onClick={createPool} className="button ml-3">
          {add()} Create new pool
        </button>
      </div>
    </>
  );
};
