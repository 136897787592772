import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import produce from 'immer';
import useMeasure from 'react-use-measure';

import { ResortArea } from '~common/model';
import { useStore } from '~components/resort-area-editor/resort-area-editor.store';
import {
  add,
  debug,
  info,
  lock,
  remove,
  reset,
  save,
} from '~components/resort-area-editor/resort-area-editor.icons';
import { ResortAreaEditorManual } from '~components/resort-area-editor/resort-area-editor.manual';
import { ResortAreaEditorMap } from '~components/resort-area-editor/resort-area-editor.map';
import { ResortAreaEditorGallery } from '~components/resort-area-editor/resort-area-editor.gallery';
import { capitalize } from '~utilities';

type Props = {
  id: number;
  startOpen: boolean;
};

export const ResortAreaEditorForm = ({ id, startOpen }: Props) => {
  const resortAreas = useStore((state) => state.resortAreas);
  const setResortAreas = useStore((state) => state.setResortAreas);

  const area = resortAreas.find((area) => area.id === id);
  const { type } = area;

  // File upload input field refs
  const layoutInputRef = useRef(null);

  // General editor state
  const [isOpen, setIsOpen] = useState(startOpen);
  const [isManualOpen, setIsManualOpen] = useState(false);
  const [editorMode, setEditorMode] = useState('locked');
  const [isDebug, setIsDebug] = useState(false);

  // Resort area general state
  const [name, setName] = useState(area.name);
  const [description, setDescription] = useState(area.description);
  const [latitude, setLatitude] = useState(area.latitude);
  const [longitude, setLongitude] = useState(area.longitude);

  // Resort area map state
  const [layout, setLayout] = useState(area.layout);
  const [layoutFile, setLayoutFile] = useState(null);
  const [scale, setScale] = useState(area.scale);
  const [items, setItems] = useState(area.items);

  // Resort area gallery state
  const [attachments] = useState(area.attachments);
  const [discardedAttachments, setDiscardedAttachments] = useState([]);
  const [attachmentsFiles, setAttachmentsFiles] = useState([]);

  // Calculate aspect ratio of layout based on image
  const [aspectRatio, setAspectRatio] = useState(undefined);
  const [ref, bounds] = useMeasure();
  useEffect(() => {
    const { width, height } = bounds;
    const aspectRatioState = width / height;
    setAspectRatio(aspectRatioState);
  }, [bounds]);

  // Calculate number of columns and rows based on scale and aspect ratio
  const columns = scale;
  const rows = Math.round(scale / aspectRatio);

  // Form handlers
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const handleScaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextScale = parseInt(event.target.value, 10);
    setScale(nextScale);
  };

  const handleLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextLatitude = parseFloat(event.target.value);
    setLatitude(nextLatitude);
  };

  const handleLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextLongitude = parseFloat(event.target.value);
    setLongitude(nextLongitude);
  };

  const handleLayoutFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLayoutFile(event.target.files[0]);
    const nextLayout = URL.createObjectURL(event.target.files[0]);
    setLayout(nextLayout);
  };

  const handleLayoutFileChange = () => {
    layoutInputRef.current && layoutInputRef.current.click();
  };

  const handleSaveDraftArea = (columns: number, rows: number) => {
    setResortAreas(
      produce(resortAreas, (draft: any) => {
        const index = draft.findIndex((area: ResortArea) => area.id === id);
        draft[index].name = name;
        draft[index].description = description;
        draft[index].latitude = latitude;
        draft[index].longitude = longitude;
        draft[index].layoutFile = layoutFile;
        draft[index].scale = scale;
        draft[index].items = items.filter(({ x, y }) => x <= columns && y <= rows);
        draft[index].discardedAttachments = discardedAttachments;
        draft[index].attachmentsFiles = attachmentsFiles || [];
      }),
    );
    setIsOpen(false);
  };

  const handleDeleteDraftArea = () => {
    if (area.id === undefined) {
      setResortAreas(
        produce(resortAreas, (draft: any) => {
          return draft.filter((area: ResortArea) => area.id !== undefined || area.name !== name);
        }),
      );
    } else {
      const verification = window.confirm(`⚠ Are you sure you want to delete ${name}?`);
      if (verification === true) {
        setResortAreas(
          produce(resortAreas, (draft: any) => {
            const index = draft.findIndex((area: ResortArea) => area.id === id);
            draft[index].name = name;
            draft[index].description = description;
            draft[index].latitude = latitude;
            draft[index].longitude = longitude;
            draft[index].layoutFile = layoutFile;
            draft[index].scale = scale;
            draft[index].items = items.filter(({ x, y }) => x <= columns && y <= rows);
            draft[index].discardedAttachments = discardedAttachments;
            draft[index].attachmentsFiles = attachmentsFiles || [];
            draft[index].isDeleted = true;
          }),
        );
        setIsOpen(false);
      }
    }
  };

  const handleRestoreDraftArea = () => {
    setResortAreas(
      produce(resortAreas, (draft: any) => {
        const index = draft.findIndex((area: ResortArea) => area.id === id);
        draft[index].name = name;
        draft[index].description = description;
        draft[index].latitude = latitude;
        draft[index].longitude = longitude;
        draft[index].layoutFile = layoutFile;
        draft[index].scale = scale;
        draft[index].items = items.filter(({ x, y }) => x <= columns && y <= rows);
        draft[index].discardedAttachments = discardedAttachments;
        draft[index].attachmentsFiles = attachmentsFiles || [];
        draft[index].isDeleted = false;
      }),
    );
  };

  const handleReset = () => {
    setItems(area.items);
  };

  // Helpers
  const getEditorMode = (editorMode: string) => {
    switch (editorMode) {
      case 'locked':
        return 'Locked';
      case 'addCabana':
        return 'Add/remove cabana';
      case 'addChair':
        return 'Add/remove chair';
      default:
        return 'Error: mode not found!';
    }
  };

  return (
    <>
      <button onClick={() => setIsOpen(!isOpen)} className="resort-area-toggle">
        <i />
        <span>{name}</span>
        <i className={cx('fa fa-angle-down', !isOpen && 'flip')} aria-hidden />
      </button>
      <div
        className={cx(
          'resort-area-container',
          isOpen ? 'resort-area-container--open' : 'resort-area-container--closed',
        )}
      >
        <div>
          <div className={cx(area.isDeleted === true && 'resort-area--deleted')}>
            <div className="row">
              <div className="col form-group">
                <label className="custom-label d-block">{capitalize(type)} name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <label className="custom-label d-block">{capitalize(type)} description</label>
                <textarea
                  name="description"
                  value={description}
                  onChange={handleDescriptionChange}
                  className="form-control"
                  rows={5}
                />
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <label className="custom-label d-block">{capitalize(type)} latitude</label>
                <input
                  type="number"
                  name="latitude"
                  value={latitude}
                  onChange={handleLatitudeChange}
                  className="form-control"
                  min={-90}
                  max={90}
                />
              </div>
              <div className="col form-group">
                <label className="custom-label d-block">{capitalize(type)} longitude</label>
                <input
                  type="number"
                  name="longitude"
                  value={longitude}
                  onChange={handleLongitudeChange}
                  className="form-control"
                  min={-180}
                  max={180}
                />
              </div>
              <div className="col form-group">
                <label className="custom-label d-block">{capitalize(type)} layout</label>
                <div>
                  <input
                    ref={layoutInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleLayoutFileUpload}
                    className="sr-only"
                  />
                  <button onClick={handleLayoutFileChange} className="button">
                    {add()} Upload layout image
                  </button>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <div className="custom-label d-block">{capitalize(type)} map</div>
                <div className="resort-area-map-tools">
                  <div className="prominent-text">
                    YOU MUST FOLLOW THESE INSTRUCTIONS IN ORDER TO ENSURE PROPER ORDERING OF CABANAS
                    AND LOUNGE CHAIRS.
                  </div>
                  <div className="mt-4 prominent-text">
                    Only remove lounge chairs or cabanas in reverse numerical order (rather than
                    randomly de-selecting / removing them) in order to maintain the integrity of the
                    lounge chair and cabana numbering system.
                  </div>
                  <div className="mt-4">
                    <button onClick={() => setIsManualOpen(true)} className="button">
                      {info()} Read full instructions
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-8">
                <div className={cx('resort-area-map', isDebug && 'resort-area-map--debug-enabled')}>
                  <img
                    ref={ref}
                    src={layout}
                    alt="resort area layout"
                    className="resort-area-map-background"
                  />
                  {aspectRatio ? (
                    <ResortAreaEditorMap
                      editorMode={editorMode}
                      columns={columns}
                      rows={rows}
                      items={items}
                      setItems={setItems}
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-4">
                <div className="resort-area-map-tools">
                  <div className="form-group">
                    <div className="custom-label d-block">Map editor mode</div>
                    <div className="prominent-text">{getEditorMode(editorMode)}</div>
                    <label className="custom-label d-block mt-3">Map editor scale ({scale})</label>
                    <input
                      type="range"
                      name="scale"
                      value={scale}
                      onChange={handleScaleChange}
                      className="form-control"
                      min={5}
                      max={50}
                    />
                    <div>
                      <small>
                        Editing the map scale can be a destructive action. After the scale is first
                        set, only change it when necessary.
                      </small>
                    </div>
                    <div className="custom-label mt-3">Map editor tools</div>
                    <div className="d-flex flex-column mt-1">
                      <button
                        onClick={() => setEditorMode('addChair')}
                        className={cx('button', editorMode === 'addChair' && 'active')}
                      >
                        {add()} Set chairs
                      </button>
                      <button
                        onClick={() => setEditorMode('addCabana')}
                        className={cx('button', editorMode === 'addCabana' && 'active', 'mt-1')}
                      >
                        {add()} Set cabanas
                      </button>
                      <button
                        onClick={() => setEditorMode('locked')}
                        className={cx('button', editorMode === 'locked' && 'active', 'mt-1')}
                      >
                        {lock()} Lock editor
                      </button>
                      <button onClick={handleReset} className="button faded mt-1">
                        {reset()} Reset editor
                      </button>
                      <button onClick={() => setIsDebug(!isDebug)} className="button faded mt-5">
                        {debug()} Review seat numbers
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <ResortAreaEditorGallery
                type={type}
                attachments={attachments}
                discardedAttachments={discardedAttachments}
                setDiscardedAttachments={setDiscardedAttachments}
                setAttachmentsFiles={setAttachmentsFiles}
              />
            </div>
            <div className="mt-3 font-bold text-right" style={{ color: 'red' }}>
              Don't forget to click "save" or you will lose all pool/beach data!
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            {area.isDeleted !== true ? (
              <button onClick={handleDeleteDraftArea} className="button faded">
                {remove()} Delete
              </button>
            ) : (
              <button onClick={handleRestoreDraftArea} className="button">
                {add()} Restore
              </button>
            )}
            <button onClick={() => handleSaveDraftArea(columns, rows)} className="button ml-3">
              {save()} Save
            </button>
          </div>
        </div>
      </div>
      {isManualOpen ? <ResortAreaEditorManual setIsManualOpen={setIsManualOpen} /> : null}
    </>
  );
};
