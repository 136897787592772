import * as React from 'react';
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { remove } from '~components/resort-area-editor/resort-area-editor.icons';

type Props = {
  setIsManualOpen: (isOpen: boolean) => void;
};

export const ResortAreaEditorManual = ({ setIsManualOpen }: Props) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <div className="resort-area-instructions">
      <div>
        <button onClick={() => setIsManualOpen(false)} className="button">
          {remove()} Close
        </button>
        <Document file="/assets/Map_Editor_v4.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (element, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
        {/* <img src="/assets/img/RT_Instructional Postcard-v4-BACK.jpg" alt="" /> */}
        {/* <img src="/assets/img/image001.png" alt="" /> */}
      </div>
    </div>
  );
};
