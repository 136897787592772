import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  isSubmitted: boolean;
  isLoading: boolean;
  message:string;
  error:string;

  constructor(private authService: AuthenticationService, private toastr: ToastrService,private router: Router,) { }

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }
  goBackToLogin(){
    this.router.navigate(['/']);
  }
  resetPassword() {
    this.isSubmitted = true;
    this.message='';
    this.error='';
    if (this.forgotPasswordForm.valid) {
      this.isLoading = true;
      this.authService.resetPassword(this.forgotPasswordForm.value).subscribe(res => {
        this.isLoading = false;
        if (res.code === 0) {
          this.message=res.message;
        } else {
          this.error = res.message;
        }
      });
    }
  }
}
