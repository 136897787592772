import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isSubmitted: boolean;
  isLoading: boolean;
  private uuid: string;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService) { }

  ngOnInit() {
    this.uuid = this.activatedRoute.snapshot.queryParams['uuid'.toString()];
    this.initForm();
    if (this.authService.getTokenId()) {
      this.router.navigate(['solay/resort']);
    }
  }
  initForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }
  login() {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.authService.login(this.loginForm.value).subscribe(res => {
        this.isLoading = false;
        if (res.code === 0 && (res.data.is_resort_admin || res.data.is_super_admin)) {
          this.authService.setTokenId(res.data.api_token);
          this.toastr.success(res.message);
          this.authService.setLogin(true);
          this.userService.setUserData(res.data);
          if (res.data.is_super_admin) {
            this.authService.setSuperAdmin(true);
            this.router.navigate(['solay/resort-list']);
          } else if (res.data.is_staff) {
            this.authService.setSuperAdmin(false);
            this.authService.setStaffUser(true);
            this.router.navigate(['solay/non-admin']);
          } else {
            this.authService.setSuperAdmin(false);
            this.authService.setStaffUser(false);
            this.router.navigate(['solay/resort']);
          }
        } else if (res.data.is_staff) {
          this.authService.setStaffUser(true);
          this.router.navigate(['solay/non-admin']);
        } else {
          this.toastr.warning(res.message);
        }
      });
    }
  }
  register() {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      this.isLoading = true;
      const registerdata = this.loginForm.value;
      if (this.uuid) {
        registerdata.uuid = this.uuid;
      }
      this.authService.register(registerdata).subscribe(res => {
        this.isLoading = false;
        if (res.code === 0) {
          this.toastr.success(res.message);
          this.authService.setTokenId(res.data.api_token);
          this.userService.setUserData(res.data);
          const navigationExtra: NavigationExtras = { state: res.data };
          if (res.data.is_staff == 1) {
            this.authService.setStaffUser(true);
            this.router.navigate(['solay/non-admin']);
          } else {
            this.router.navigate(['solay/resort']);
          }
        } else {
          this.toastr.warning(res.message);
        }
      });
    }
  }

}
