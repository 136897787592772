import { Injectable } from '@angular/core';
import { UserData, User } from '../../common/model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: any;
  resorts: any[];
  constructor() { }
  // setUserData(userData: UserData) {

  //   localStorage.setItem('userData', JSON.stringify(userData));
  // }
  setUserData(userData: UserData) {
    this.user = userData;
  }
  updateResort(resort) {
    const data = this.getFullData();
    if (data) {
      data.resort = resort;
      this.setUserData(data);
    }
  }
  setUser(user) {
    this.user = user;
  }
  updatePool(pools) {
    const data = this.getFullData();
    if (data) {
      data.resort.pools = pools;
      this.setUserData(data);
    }
  }
  updateBeach(beaches) {
    const data = this.getFullData();
    if (data) {
      data.resort.beaches = beaches;
      this.setUserData(data);
    }
  }
  getUserData(field: string) {
    if (this.user) {
      return this.user[field];
    }
    return null;
  }
  getFullData() {
    return this.user;
  }
  resetUserData(){
    this.user = null;
  }
  setAllResorts(resorts) {
    this.resorts = resorts;
  }
  getAllResorts() {
    return this.resorts;
  }
  getResort(id) {
    if (this.resorts) {
      return this.resorts.find(elm => elm.id === id);
    }
  }
  updateResortField(filed, data) {
    this.user.resort[filed] = data;
  }
}
