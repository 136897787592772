import create from 'zustand';
import createVanilla from 'zustand/vanilla';

import { ResortArea } from '~common/model';

type State = {
  resortAreas: ResortArea[];
  setResortAreas: (resortAreas: ResortArea[]) => void;
};

export const store = createVanilla<State>((set) => ({
  resortAreas: [],
  setResortAreas: (resortAreas: ResortArea[]) => set((state) => ({ ...state, resortAreas })),
}));

export const useStore = create<State>(store);
