import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FooterComponent } from './footer/footer.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};



@NgModule({
  declarations: [HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options)
  ],
  exports: [HeaderComponent,FooterComponent]
})
export class BaseModule { }
