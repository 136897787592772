import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/authentication/services/user.service';
import { ResortService } from '../../services/resort.service';
import { Router } from '@angular/router';
import { UserData } from 'src/app/common/model';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';

@Component({
  selector: 'app-resort-list',
  templateUrl: './resort-list.component.html',
  styleUrls: ['./resort-list.component.scss'],
})
export class ResortListComponent implements OnInit {
  userData: UserData;
  resorts: any;

  constructor(
    private userService: UserService,
    private resortservice: ResortService,
    private router: Router,
    private toasterService: ToastrService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.getAllResorts();
  }
  getAllResorts() {
    this.userData = this.userService.getFullData();
    if (this.userData) {
      if (this.userData.is_super_admin) {
        this.authService.setSuperAdmin(true);
        this.resortservice.getAllResorts().subscribe((res) => {
          if (res.code === 0) {
            this.resorts = res.data;
            this.userService.setAllResorts(this.resorts);
          }
        });
      } else {
        this.authService.setSuperAdmin(false);
        this.router.navigate(['solay/resort']);
      }
    } else {
      this.router.navigate(['solay/resort']);
    }
  }
  loadResort(id) {
    this.router.navigate([`solay/resort/${id}`]);
  }
}
