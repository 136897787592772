import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { InputBase } from './input-base';
import { isArray } from 'util';

/**
 * Create a form based on a formSchema, see InputBase
 *
 * Usage:
 * <code>
 *   <app-dynamic-form
 *     [keyValueFormData]='resort.white_label_json'
 *     (setWhiteLabelData)="setWhiteLabelData($event)"
 *     [formSchema]='whiteLabelFormSchema'
 *   ></app-dynamic-form>
 * </code>
 */
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [  ]
})
export class DynamicFormComponent implements OnInit {

  // Form data
  @Input() keyValueFormData: [];
  // Form structure, what each input looks like
  @Input() formSchema: InputBase<any>[];

  // Callback event, call `this.setWhiteLabelData.emit(this.form);` to pass FormGroup to parent
  @Output() public formGroupChangeEvent = new EventEmitter<any>();
  form: FormGroup;

  constructor() {  }

  ngOnInit() {
    this.setSchemaValues(this.keyValueFormData);
    this.form = this.toFormGroup(this.formSchema);
    this.formGroupChangeEvent.emit(this.form);
  }

  onChange() {
    this.formGroupChangeEvent.emit(this.form);
  }

  private setSchemaValues(keyValueFormData) {
    if (keyValueFormData) {
      this.formSchema.forEach((schemaInput, index) => {
        if (keyValueFormData[schemaInput.key]) {
          this.formSchema[index].value = keyValueFormData[schemaInput.key];
        }
      });
      return this.formSchema.sort((a, b) => a.order - b.order);
    }
  }

  /**
   * create FormGroup from schema
   * @param formSchema
   */
  toFormGroup(formSchema: InputBase<any>[]) {
    const group: any = {};

    if (isArray(formSchema)) {
      formSchema.forEach(input => {
        if (typeof input !== 'undefined') {
          const validators = [];

          if (input.validatorName) {
            validators.push(this.getValidator(input.validatorName));
          }
          if (input.required) {
            validators.push(Validators.required);
          }

          group[input.key] = new FormControl(input.value || '', validators);
        }
      });
    }
    return new FormGroup(group);
  }

  private getValidator(validatorName) {
    switch (validatorName) {
      case 'isHexColor':
        return Validators.pattern('\#([a-fA-F0-9]{6})');
      case 'isSlug':
        return Validators.pattern('[a-z0-9\-]*');
      case 'isEmail':
        return Validators.email;
      case 'isImage':
        // @TODO include more here:
        // It should either be a filepicker or an existing image URL
        return Validators.required;
    }
  }
}
