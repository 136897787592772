import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Resort } from '../../common/model';

@Injectable({
  providedIn: 'root',
})
export class ResortService {
  private resortEndpoint = `${environment.endpoint}/resorts`;
  private createPoolEndpoint = `${environment.endpoint}/resorts/pool/create`;
  private updatePoolEndpoint = `${environment.endpoint}/resorts/pool`;
  private deleteAttachmentEndpoint = `${environment.endpoint}/attachments`;
  private loungeAreaEndpoint = `${environment.endpoint}/lounges`;
  private cabanaEndpoint = `${environment.endpoint}/cabanas`;
  private createBeachEndpoint = `${environment.endpoint}/resorts/beach/create`;
  private updatebeachEndpoint = `${environment.endpoint}/resorts/beach`;
  private sendCodeEndPoint = `${environment.endpoint}/code/send`;
  private inviteUserEndpoint = `${environment.endpoint}/members/invite`;
  private reservationEndpoint = `${environment.endpoint}/reservations`;
  private cancelReservationEndpoint = `${environment.endpoint}/reservation`;

  private saveResort = new BehaviorSubject<boolean>(false);
  private apiCallCount = new BehaviorSubject<number>(-1);
  private enableUpdate = new BehaviorSubject<boolean>(false);

  get apiCount() {
    return this.apiCallCount.asObservable();
  }

  addApiCount(value: number) {
    // let val={
    //   value:value
    // }
    this.apiCallCount.next(value);
  }

  get saveAllDataObservable() {
    return this.saveResort.asObservable();
  }
  saveAllData(value: boolean) {
    this.saveResort.next(value);
  }
  cancelReservation(id): Observable<any> {
    return this.http.post(`${this.cancelReservationEndpoint}/update-status/${id}`, {
      status: 'cancelled',
    });
  }

  getEnableUpdate() {
    return this.enableUpdate.asObservable();
  }
  enableUpdates(value: boolean) {
    this.enableUpdate.next(value);
  }

  constructor(private http: HttpClient) {}
  createResort(resort): Observable<Resort> {
    return this.http.post<Resort>(this.resortEndpoint, resort);
  }
  updateResort(id, resort) {
    return this.http.post<Resort>(`${this.resortEndpoint}/${id}`, resort);
  }
  createPool(pool): Observable<any> {
    return this.http.post(this.createPoolEndpoint, pool);
  }
  updatePool(id, pool): Observable<any> {
    return this.http.post(`${this.updatePoolEndpoint}/${id}`, pool);
  }
  createCabana(cabana): Observable<any> {
    return this.http.post(this.cabanaEndpoint, cabana);
  }
  updateCabana(id, cabana): Observable<any> {
    return this.http.post(`${this.cabanaEndpoint}/${id}`, cabana);
  }
  createLounge(lounge): Observable<any> {
    return this.http.post(this.loungeAreaEndpoint, lounge);
  }
  updateLounge(id, lounge): Observable<any> {
    return this.http.post(`${this.loungeAreaEndpoint}/${id}`, lounge);
  }
  createBeach(beach): Observable<any> {
    return this.http.post(this.createBeachEndpoint, beach);
  }
  updateBeach(id, beach): Observable<any> {
    return this.http.post(`${this.updatebeachEndpoint}/${id}`, beach);
  }
  sendCode(data): Observable<any> {
    return this.http.post(this.sendCodeEndPoint, data);
  }
  getAllResorts(): Observable<any> {
    return this.http.get(this.resortEndpoint);
  }
  getSingleResort(id): Observable<any> {
    return this.http.get(`${this.resortEndpoint}/${id}`);
  }
  deleteBeach(id: number): Observable<any> {
    return this.http.delete(`${this.updatebeachEndpoint}/${id}`);
  }
  deletePool(id: number): Observable<any> {
    return this.http.delete(`${this.updatePoolEndpoint}/${id}`);
  }
  deleteAttachments(id: number): Observable<any> {
    return this.http.delete(`${this.deleteAttachmentEndpoint}/${id}`);
  }
  deleteLoungeArea(id: number): Observable<any> {
    return this.http.delete(`${this.loungeAreaEndpoint}/${id}`);
  }
  deleteCabana(id: number): Observable<any> {
    return this.http.delete(`${this.cabanaEndpoint}/${id}`);
  }
  sendInviteCode(data): Observable<any> {
    return this.http.post(`${this.inviteUserEndpoint}`, data);
  }
  getReservations(resortId): Observable<any> {
    return this.http.get(`${this.reservationEndpoint}/${resortId}`);
  }
}
