import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ResortService } from 'src/app/solay/services/resort.service';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/authentication/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  codeToGuestForm: FormGroup;
  inviteStaffForm: FormGroup;
  isCodeSuceed = false;
  isLoggedIn$: Observable<boolean>;
  isSuperAdmin$: Observable<boolean>;
  isStaffUser$: Observable<boolean>;
  fakeArray = new Array(14);
  inviteSent: boolean;
  reservations: any;
  dataInnitiated: boolean;
  reservationList: any;
  enableUpdate: boolean = false;
  disabledCancelId = [];

  constructor(
    private modalServices: NgbModal, private resortService: ResortService, private toasterService: ToastrService,
    private userService: UserService,
    private authService: AuthenticationService, private router: Router) {
  }

  ngOnInit() {
    this.initCodeToGuestForm();
    this.initInviteForm();
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isSuperAdmin$ = this.authService.isSuperadmin;
    this.isStaffUser$ = this.authService.isStaffUser;
    this.enableUpdate = false;
    this.resortService.getEnableUpdate().subscribe(res => {
      this.enableUpdate = res;
    })
  }
  initInviteForm() {
    this.inviteStaffForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('', Validators.required)
    });
  }
  initCodeToGuestForm() {
    this.codeToGuestForm = new FormGroup({
      email: new FormControl(''),
      mobile_no: new FormControl(''),
      length_of_stay: new FormControl('')
    }, { validators: this.customeValidator });
  }
  openMessageModal(modalName) {
    this.isCodeSuceed = false;
    this.modalServices.open(modalName, { size: 'xl' });
  }
  openInviteModal(modalName) {
    this.modalServices.open(modalName, { size: 'xl' });
  }
  customeValidator(codeToGuestFormGroup: any) {
    return (codeToGuestFormGroup.controls['mobile_no'.toString()].value ||
      codeToGuestFormGroup.controls['email'.toString()].value) ? null : { oneFieldRequired: true };
  }
  changeDaysOfStay(event) {
    this.codeToGuestForm.get('length_of_stay').setValue(event.target.value);
  }
  changeRole(value) {
    this.inviteStaffForm.get('role').setValue(value);
  }
  sendInvite() {
    if (this.inviteStaffForm.valid) {
      this.resortService.sendInviteCode(this.inviteStaffForm.value).subscribe(res => {
        if (res.code === 0) {
          this.inviteSent = true;
          this.inviteStaffForm.get('email').setValue('');
          this.toasterService.success('Member was invited successfully');
        } else {
          this.toasterService.error(res.message);
        }
      }, err => {
        this.toasterService.error(err.error.message);
      });
    } else {
      this.toasterService.warning('Provide input for all fileds');
    }
  }
  saveCodeToGuest() {
    if (this.codeToGuestForm.valid) {
      this.resortService.sendCode(this.codeToGuestForm.value).subscribe(res => {
        if (res.code === 0) {
          this.isCodeSuceed = true;
          this.codeToGuestForm.patchValue({ email: '', mobile_no: '' });
        } else {
          this.toasterService.error(res.message);
        }
      }, err => {
        this.toasterService.error(err.error.message);
      });
    } else if (this.codeToGuestForm.get('mobile_no').errors) {
      this.toasterService.warning('Provide 10 digits for mobile no');
    } else {
      this.toasterService.warning('Provide value for required fields');
    }
  }
  logout() {
    this.authService.clearTokenId();
    this.router.navigate(['login']);
    this.authService.setLogin(false);
  }
  navigateToList() {
    this.router.navigate(['solay/resort-list']);
  }
  goToGuestBooking(modal) {
    const resortId = this.userService.getUserData('resort') ? this.userService.getUserData('resort').id : null;
    if (resortId) {
      this.resortService.getReservations(resortId).subscribe(res => {
        if (res.code === 0) {
          this.reservationList = res.data || [];
          this.dataInnitiated = true;
          this.modalServices.open(modal, { size: 'xl' });
        } else {
          this.toasterService.error(res.message);
        }
      }, err => {
        this.toasterService.error(err.error.message);
      });
    } else {
      this.toasterService.error('Error Occured');
    }


  }
  closeModal() {
    this.modalServices.dismissAll();

  }
  rentalType(type: string) {
    switch (type) {
      case 'fullday':
        return 'All day';
        break;
      case 'morning':
        return 'Morning';
        break;
      case 'afternoon':
        return 'Afternoon';
        break;
      default:
        return null;
    }
  }
  getStatus(status: string) {
    switch (status) {
      case 'completed':
        return 'Completed';
        break;
      case 'ready_for_checkin':
        return 'Ready for checkin';
        break;
      case 'reserved':
        return 'Reserved';
        break;
      case 'cancelled':
        return 'Cancelled';
        break;
      default:
        return '-';
        break;
    }
  }
  saveResort() {
    this.resortService.saveAllData(true);
  }
  cancelReservation(reservation, reservationId) {
    this.disabledCancelId.push(reservation.id);
    this.resortService.cancelReservation(reservation.id).subscribe(res => {
      if (res.code === 0) {
        this.toasterService.success('Reservation cancelled successfully');
        this.reservationList[reservationId].status = res.data.status;
      } else {
        this.toasterService.error('Error occured');
      }
      this.disabledCancelId.splice(this.disabledCancelId.indexOf(reservation.id), 1);
    }, err => {
      this.toasterService.error('Error occured');
      this.disabledCancelId.splice(this.disabledCancelId.indexOf(reservation.id), 1);
    });
  }
}
