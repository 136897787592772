import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  TemplateRef,
  HostListener,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserData, ResortArea, ResortAreaItem } from '../../../common/model';
import { UserService } from 'src/app/authentication/services/user.service';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ResortService } from '../../services/resort.service';
import { ToastrService } from 'ngx-toastr';
import { CanvasWhiteboardComponent, CanvasWhiteboardOptions } from 'ng2-canvas-whiteboard';
import { MapEditModalComponent } from '../../map-edit-modal/map-edit-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatCalendarCellCssClasses, MatCalendar } from '@angular/material/datepicker';
import { InputBase } from '../dynamic-form/input-base';
import { store } from '../resort-area-editor/resort-area-editor.store';

@Component({
  selector: 'app-resort',
  templateUrl: './resort.component.html',
  styleUrls: ['./resort.component.scss'],
  viewProviders: [CanvasWhiteboardComponent],
})
export class ResortComponent implements OnInit {
  userData: UserData;
  resortForm: FormGroup;
  isWhiteLabelEnabled: boolean;
  whiteLabelFormGroup: FormGroup;
  whiteLabelFormSchema: InputBase<any>[] = [
    {
      key: 'name',
      help: 'Your hotel name.',
      type: 'text',
      error: '',
      label: 'Hotel Name',
      order: 0,
      value: '',
      required: true,
      controlType: 'textbox',
    },
    {
      key: 'subdomain',
      help: 'Your new subdomain. For example entering "myresort" will give you a URL of ' +
        'https://myresort.solayapp.com/ that you share with your guests. Lower case a-z, numbers ' +
        'and dashes.',
      type: 'text',
      error: 'This will be used in your white label app URL. For example, if you entered ' +
        '"myresort" then your URL would be https://myresort.solayapp.com/. Letters (a-z), ' +
        'numbers, and dashes only.',
      label: 'Hotel Subdomain',
      order: 1,
      value: '',
      required: true,
      controlType: 'textbox',
      validatorName: 'isSlug'
    },
    {
      key: 'email',
      help: 'Email notifications will be sent to your guests from an email address such as ' +
        'myresort@solayapp.com. If a user attempts to reply to that email, it will be ' +
        'undeliverable. If you provide us a reply to email address here, any user replies will ' +
        'go directly to you at that email address.',
      type: 'email',
      error: 'Reply to Email is required and must be in the email format.',
      label: 'Reply to Email',
      order: 2,
      value: '',
      required: true,
      controlType: 'textbox',
      validatorName: 'isEmail'
    },
    {
      key: 'logo',
      help: 'This logo will appear in various locations throughout the app. The logo should be a ' +
        'transparent png file.',
      type: 'file',
      error: 'Hotel Logo is required',
      label: 'Hotel Logo',
      order: 3,
      value: '',
      required: true,
      controlType: 'textbox',
      validatorName: 'isImage'
    },
    {
      key: 'welcome',
      help: 'This replaces the "This spot is yours." image on our welcome screen.',
      type: 'text',
      error: 'Welcome is required',
      label: 'Welcome',
      order: 4,
      value: '',
      required: true,
      controlType: 'textbox',
    },
    {
      key: 'logo_square',
      help: 'This icon will be used in the messaging feature of the app. The icon should be in ' +
        'square format as a transparent png.',
      type: 'file',
      error: 'Brand Icon is required',
      label: 'Brand Icon',
      order: 4,
      value: '',
      required: true,
      controlType: 'textbox',
      validatorName: 'isImage'
    },
    {
      key: 'color_secondary',
      help: 'This color will be used to highlight some other content and buttons throughout the ' +
        'app. It should be on of your brand\'s secondary colors.',
      type: 'color',
      error: 'Secondary Color is required.',
      label: 'Secondary Color',
      order: 5,
      value: '',
      required: true,
      controlType: 'color',
      validatorName: 'isHexColor'
    },
    {
      key: 'color_highlight',
      help: 'This color will be used throughout the app for backgrounds and buttons. It should be your brand\'s primary color.',
      type: 'color',
      error: 'Primary Brand Color is required.',
      label: 'Primary Brand Color',
      order: 6,
      value: '',
      required: true,
      controlType: 'color',
      validatorName: 'isHexColor'
    },
    {
      key: 'color_background',
      help: 'This color will be used as the background color for the welcome, login and signup screens, both above colors should ' +
        'contrast well against this color.',
      type: 'color',
      error: 'Background Color is required.',
      label: 'Background Color',
      order: 7,
      value: '',
      required: true,
      controlType: 'color',
      validatorName: 'isHexColor'
    },
    {
      key: 'timeslot',
      help: 'Default will allow for your guests to book \'morning\', \'afternoon\', or \'all ' +
        'day\'. Simple will allow for your guests to book only \'morning\' or \'afternoon\'.',
      error: 'Timeslot is required.',
      label: 'Timeslot Options',
      order: 8,
      value: '',
      options: [
        {
          key: 'default',
          value: 'Default'
        },
        {
          key: 'simple',
          value: 'Simple'
        }
      ],
      required: true,
      controlType: 'dropdown'
    }
  ];
  poolForm = new FormGroup({
    pools: new FormArray([]),
  });
  beachForm = new FormGroup({
    beaches: new FormArray([]),
  });
  poolList: FormArray = new FormArray([]);
  beachList: FormArray = new FormArray([]);

  math = Math;
  resort;
  pools: any[] = [];
  beaches: any[] = [];
  currentPoolImage: any;
  formInited: any;
  resortVicinityMap;
  resortVicinityMapThumbnail;
  poolVicinitymap: any;
  cabanaphoto: string | Blob;
  loungeVicinityMap: any;
  loungePhoto: string | Blob;
  beachVicinitymap: any;
  loungeVicinityMapPool: any;
  loungeVicinityMapBeach: any;
  loungePoolPhoto: Blob;
  loungeBeachPhoto: any;
  loungeBeachVicinityMap: any;
  cabanaBeachVicinityMap: any;
  cabanaPoolVicinityMap: any;
  cabanaPoolPhoto: any;
  cabanaBeachPhoto: any;
  codeToGuestForm: FormGroup;
  poolVicinitymapThumbnail: string;
  loungePoolPhotoThumbnail: string;
  cabanaPoolVicinityMapThumbnail: string;
  cabanaPoolPhotoThumbnail: string;
  loungeBeachPhotoThumbnail: string;
  loungeBeachVicinityMapThumbnail: string;
  cabanaBeachPhotoThumbnail: string;
  cabanaBeachVicinityMapThumbnail: string;
  poolPhoto: any;
  poolPhotoThumbnail: string | any[];
  beachPhoto: any;
  beachPhotoThumbnail: string | any[];
  beachvicinitymapThumbnail: any;
  poolLayoutImageThumbnail: string;
  poolLayoutImage: any;
  beachLayoutImage: any;
  beachLayoutImageThumbnail: string | any[];
  base64Img: string | ArrayBuffer;
  canvasLoaded = true;
  canvasOptions: CanvasWhiteboardOptions;
  overlaying = true;
  editVicinityMapOrder = ['lounge', 'cabana', 'pool'];
  editCounter = 0;
  editButtonText: string;
  NextButtonText = 'Next';
  isVicinityMapExists = false;
  isBeachVicinityMapExists: boolean;
  disableVisinityUpdateButton: boolean;
  isSuperAdmin = false;
  blackoutDay;
  specialHolidays;
  blackoutDates;
  customPatterns = { '0': { pattern: new RegExp('[-?0-9]') } };
  startAt = new Date(Date.now());
  minDate = new Date(Date.now());
  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
  @ViewChild('termsModal', { static: true }) termsModal: TemplateRef<any>;
  @ViewChild('confirmationModal', { static: true }) consfirmationModal: TemplateRef<any>;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.enableUpdate) {
      $event.returnValue = false;
    }
  }
  @HostListener('window:popstate', ['$event'])
  onPopState($event: any) {
    if (this.enableUpdate) {
      $event.returnValue = false;
    }
  }

  newPools = [];
  newBeaches = [];
  termsAccepted: boolean;
  showTermsModal: boolean;
  resortImageDeleted: boolean;
  loungeVicinityMapThumbnail: any;
  apiCallCount: number;
  loading: boolean = false;
  enableUpdate: boolean = false;
  subscribedChangeEvent: any = [];

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private resortService: ResortService,
    private toasterService: ToastrService,
    private cdRef: ChangeDetectorRef,
    private modalServices: NgbModal,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    //this.checkTermsAccepted();
  }

  ngOnInit() {
    this.setUserData();
    this.initEditor();
    this.apiCallCount = 0;
    // if (this.showTermsModal) {
    //   this.modalServices.open(this.consfirmationModal, { size: 'xl', centered: true });
    // }
    this.resortService.saveAllDataObservable.subscribe((res) => {
      if (res) {
        // this.finalSave();
        this.resortService.saveAllData(false);
        this.checkForTermAndCondition();
      }
    });
    this.resortService.apiCount.subscribe((value) => {
      if (value === 0) {
        console.log(value);
        this.apiCallCount = 0;
        this.userService.resetUserData();
        this.setUserData(true);
      }
    });
  }

  createResort() {
    if (!this.resort.id) {
      this.saveResort(false);
    }
  }
  checkTermsAccepted() {
    const checkTerms = this.router.getCurrentNavigation()
      ? this.router.getCurrentNavigation().extras.state
      : null;
    if (checkTerms) {
      this.showTermsModal = true;
    }
  }
  initEditor() {
    this.canvasOptions = {
      drawingEnabled: true,
      lineWidth: 5,
      strokeColor: 'rgb(255, 184, 77)',
    };
  }
  initResortForAdmin(resort) {
    this.resort = resort;
    if (this.resort.pools) {
      this.pools = this.resort.pools;
    }
    if (this.resort.beaches) {
      this.beaches = this.resort.beaches;
    }
    this.initFormResortForm();
    this.initPoolsForm();
    this.initBeachForm();
    this.formInited = true;
  }
  setUserData(reInit = false) {
    this.route.params.subscribe((params) => {
      if (params['id'.toString()]) {
        const id = params['id'.toString()];
        this.authService.getUser().subscribe((res) => {
          if (res.code === 0) {
            if (res.data.is_super_admin) {
              this.isSuperAdmin = true;
              this.authService.setSuperAdmin(true);
              this.resortService.getSingleResort(id).subscribe((response) => {
                if (res.code === 0) {
                  this.initResortForAdmin(response.data);
                  res.data.resort = response.data;
                  this.userService.setUser(res.data);
                  // check here
                  if (reInit) {
                    this.modalServices.dismissAll();
                    this.loading = false;
                    const hasSeenModal = localStorage.getItem('hasSeenModal');
                    if (hasSeenModal !== 'true') {
                      this.modalServices.open(this.consfirmationModal, { size: 'xl', centered: true });
                    }
                    window.localStorage.setItem('hasSeenModal', 'true')
                    this.resortService.enableUpdates(false);
                    this.enableUpdate = false;
                  }
                }
              });
            } else {
              this.router.navigate(['solay/resort']);
            }
          }
        });
      } else {
        this.userData = this.userService.getFullData();
        if (!this.userData) {
          this.authService.getUser().subscribe((res) => {
            if (res.code === 0) {
              this.userService.setUser(res.data);
              this.isSuperAdmin = res.data.is_super_admin ? true : false;
              if (this.isSuperAdmin) {
                this.authService.setSuperAdmin(true);
                this.router.navigate(['solay/resort-list']);
              } else if (res.data.is_staff == 1) {
                this.authService.setStaffUser(true);
                this.router.navigate(['solay/non-admin']);
              }
              this.authService.setSuperAdmin(false);
              this.userData = res.data;
              if (this.userData.resort) {
                this.resort = this.userData.resort;
              } else {
                this.resort = {
                  activated_at: null,
                  address: null,
                  beaches: [],
                  blackout_dates: [],
                  cabana_amenities: null,
                  cabana_full_day_price: '',
                  cabana_full_day_price_special: '',
                  cabana_full_day_price_weekend: '',
                  cabana_half_day_price: '',
                  email: null,
                  id: '',
                  lounge_full_day_price: '',
                  lounge_half_day_price: '',
                  name: null,
                  pools: [],
                  resort_image: null,
                  special_holiday_dates: [],
                  telephone: null,
                  timezone: null,
                };
              }
              if (this.userData.resort && this.userData.resort.pools) {
                this.pools = this.userData.resort.pools;
              }
              if (this.userData.resort && this.userData.resort.beaches) {
                this.beaches = this.userData.resort.beaches;
              }

              this.unSubscribeChangeEvent();

              this.initFormResortForm();
              this.initPoolsForm();
              this.initBeachForm();
              this.formInited = true;
              // check here

              if (reInit) {
                this.modalServices.dismissAll();
                this.loading = false;
                const hasSeenModal = localStorage.getItem('hasSeenModal');
                if (hasSeenModal !== 'true') {
                  this.modalServices.open(this.consfirmationModal, { size: 'xl', centered: true });
                }
                window.localStorage.setItem('hasSeenModal', 'true')
                this.resortService.enableUpdates(false);
                this.enableUpdate = false;
              }
            } else {
              this.router.navigate(['login']);
            }
          });
        } else {
          if (this.userData.resort) {
            this.resort = this.userData.resort;
          } else {
            this.resort = {
              activated_at: null,
              address: null,
              beaches: [],
              blackout_dates: [],
              cabana_amenities: null,
              cabana_full_day_price: '',
              cabana_full_day_price_special: '',
              cabana_full_day_price_weekend: '',
              cabana_half_day_price: '',
              email: null,
              id: '',
              lounge_full_day_price: '',
              lounge_half_day_price: '',
              name: null,
              pools: [],
              resort_image: null,
              special_holiday_dates: [],
              telephone: null,
              timezone: null,
            };
          }
          this.isSuperAdmin = this.userData.is_super_admin ? true : false;
          if (this.isSuperAdmin) {
            this.authService.setSuperAdmin(true);
            this.router.navigate(['solay/resort-list']);
          } else if (this.userData.is_staff == 1) {
            this.authService.setStaffUser(true);
            this.router.navigate(['solay/non-admin']);
          }
          this.authService.setSuperAdmin(false);
          if (this.userData.resort && this.userData.resort.pools) {
            this.pools = this.userData.resort.pools;
          }
          if (this.userData.resort && this.userData.resort.beaches) {
            this.beaches = this.userData.resort.beaches;
          }

          this.initFormResortForm();
          this.initPoolsForm();
          this.initBeachForm();
          this.formInited = true;
        }
      }
    });
  }
  unSubscribeChangeEvent() {
    this.subscribedChangeEvent.forEach((data) => {
      data.unsubscribe();
    });
  }
  initBeachForm() {
    this.beachForm = new FormGroup({
      beaches: new FormArray([]),
    });
    this.beachList = this.beachForm.get('beaches') as FormArray;
    if (this.beaches.length > 0) {
      this.beaches.forEach((element) => {
        this.beachList.push(this.createPoolOrBeach(element));
      });
    }
    const subscription = this.beachForm.valueChanges.subscribe((val) => {
      this.resortService.enableUpdates(true);
      this.enableUpdate = true;
    });
    this.subscribedChangeEvent.push(subscription);
  }
  initPoolsForm() {
    this.poolForm = new FormGroup({
      pools: new FormArray([]),
    });
    this.poolList = this.poolForm.get('pools') as FormArray;
    if (this.pools.length > 0) {
      this.pools.forEach((element) => {
        this.poolList.push(this.createPoolOrBeach(element));
      });
    }
    const subscription = this.poolForm.valueChanges.subscribe((val) => {
      this.resortService.enableUpdates(true);
      this.enableUpdate = true;
    });
    this.subscribedChangeEvent.push(subscription);
  }
  initFormResortForm() {
    this.resortForm = new FormGroup({
      name: new FormControl(this.resort ? this.resort.name : '', [Validators.required]),
      address: new FormControl(this.resort ? this.resort.address : ''),
      telephone: new FormControl(this.resort ? this.resort.telephone : ''),
      email: new FormControl(this.resort ? this.resort.email : '', [Validators.email]),
      cabana_full_day_price: new FormControl(this.resort ? this.resort.cabana_full_day_price : ''),
      cabana_full_day_price_weekend: new FormControl(
        this.resort ? this.resort.cabana_full_day_price_weekend : '',
      ),
      cabana_full_day_price_special: new FormControl(
        this.resort ? this.resort.cabana_full_day_price_special : '',
      ),
      cabana_half_day_price: new FormControl(this.resort ? this.resort.cabana_half_day_price : ''),
      lounge_half_day_price: new FormControl(this.resort ? this.resort.lounge_half_day_price : ''),
      lounge_full_day_price: new FormControl(this.resort ? this.resort.lounge_full_day_price : ''),
      timezone: new FormControl(this.resort ? this.resort.timezone : ''),
    });
    if (this.resort && this.resort.cabana_amenities) {
      this.resortForm.addControl('cabana_amenities', new FormControl(this.resort.cabana_amenities));
    }
    if (this.resort && this.resort.white_label_json) {
      this.isWhiteLabelEnabled = true;
    }

    const subscription = this.resortForm.valueChanges.subscribe((val) => {
      this.resortService.enableUpdates(true);
      this.enableUpdate = true;
    });
    this.subscribedChangeEvent.push(subscription);
  }
  createPoolOrBeach(data?) {
    const lounges = new FormArray([]);
    const cabanas = new FormArray([]);
    if (data) {
      if (data.cabanas.length > 0) {
        data.cabanas.forEach((cabana) => {
          cabanas.push(this.createCabana(data.id, cabana));
        });
      }
      if (data.lounges.length > 0) {
        data.lounges.forEach((lounge: any) => {
          lounges.push(this.createLounge(data.id, lounge));
        });
      }
    }

    const poolBeachGroup = new FormGroup({
      name: new FormControl(data ? data.name : ''),
      description: new FormControl(data ? data.description : ''),
      latitude: new FormControl(data ? data.latitude : ''),
      longitude: new FormControl(data ? data.longitude : ''),
      resort_id: new FormControl(data ? data.resort_id : this.resort ? this.resort.id : '', [
        Validators.required,
      ]),
      id: new FormControl(data ? data.id : ''),
      lounge_reservation: new FormControl(data ? data.lounge_reservation : ''),
      cabana_reservation: new FormControl(data ? data.cabana_reservation : ''),
      layout: new FormControl(data ? data.layout : ''),
      layout_send: new FormControl(data ? data.layout_send : ''),
      layout_preview: new FormControl(data ? data.layout_preview : ''),
      cabanas,
      lounges,
    });

    const subscription = poolBeachGroup.valueChanges.subscribe((val) => {
      this.resortService.enableUpdates(true);
      this.enableUpdate = true;
    });
    this.subscribedChangeEvent.push(subscription);

    return poolBeachGroup;
  }
  addNewPool() {
    if (
      !(this.poolForm.get('pools') as FormArray).controls.length ||
      (this.poolForm.get('pools') as FormArray).controls[
        (this.poolForm.get('pools') as FormArray).controls.length - 1
      ].get('name').value
    ) {
      (this.poolForm.get('pools') as FormArray).push(this.createPoolOrBeach());
      setTimeout(() => {
        window.location.hash = '';
        window.location.hash = 'pool';
      }, 1);
    }
    window.location.hash = '';
    window.location.hash = 'pool';
  }
  addNewBeach() {
    if (
      !(this.beachForm.get('beaches') as FormArray).controls.length ||
      (this.beachForm.get('beaches') as FormArray).controls[
        (this.beachForm.get('beaches') as FormArray).controls.length - 1
      ].get('name').value
    ) {
      (this.beachForm.get('beaches') as FormArray).push(this.createPoolOrBeach());
      setTimeout(() => {
        window.location.hash = '';
        window.location.hash = 'beach';
      }, 1);
    }
    window.location.hash = '';
    window.location.hash = 'beach';
  }
  showHideLoungesOrCabanaForBeach(event, beach, i, cabana) {
    const isChecked = event.target.checked;

    if (isChecked) {
      if (cabana && !beach.cabanas.length) {
        this.addNewCabana(beach, i, true);
      } else if (!beach.lounges.length) {
        this.addNewLoungeArea(beach, i, true);
      }
    }
  }
  showHideLoungesOrCabanaForPool(event, pool, i, cabana) {
    const isChecked = event.target.checked;

    if (isChecked) {
      if (cabana && !pool.cabanas.length) {
        this.addNewCabana(pool, i);
      } else if (!pool.lounges.length) {
        this.addNewLoungeArea(pool, i);
      }
      return;
    }
  }
  addlounges(event, target, i, beach = false) {
    if (event.target.checked) {
      if (beach) {
        ((this.beachForm.get('beaches') as FormArray).controls[i].get(
          'lounges',
        ) as FormArray).controls.push(this.createLounge(target.id));
      } else {
        ((this.poolForm.get('pools') as FormArray).controls[i].get(
          'lounges',
        ) as FormArray).controls.push(this.createLounge(target.id));
      }
    }
  }
  addNewLoungeArea(target, i, beach = false) {
    if (beach) {
      // if (!((this.beachForm.get('beaches') as FormArray).controls[i].get('lounges') as FormArray).controls.length ||
      //   ((this.beachForm.get('beaches') as FormArray).controls[i].get('lounges') as FormArray)
      //     .controls[((this.beachForm.get('beaches') as FormArray).controls[i].get('lounges') as FormArray)
      //       .controls.length - 1].get('id').value) {
      ((this.beachForm.get('beaches') as FormArray).controls[i].get(
        'lounges',
      ) as FormArray).controls.push(this.createLounge(target.id));
      // }
    } else {
      // if (!((this.poolForm.get('pools') as FormArray).controls[i].get('lounges') as FormArray).controls.length ||
      //   ((this.poolForm.get('pools') as FormArray).controls[i].get('lounges') as FormArray)
      //     .controls[((this.poolForm.get('pools') as FormArray)
      //       .controls[i].get('lounges') as FormArray).controls.length - 1].get('id').value) {
      ((this.poolForm.get('pools') as FormArray).controls[i].get(
        'lounges',
      ) as FormArray).controls.push(this.createLounge(target.id));
      // }
    }
  }
  addNewCabana(target, i, beach = false) {
    if (beach) {
      // if (!((this.beachForm.get('beaches') as FormArray).controls[i].get('cabanas') as FormArray).controls.length ||
      //   ((this.beachForm.get('beaches') as FormArray).controls[i].get('cabanas') as FormArray)
      //     .controls[((this.beachForm.get('beaches') as FormArray)
      //       .controls[i].get('cabanas') as FormArray).controls.length - 1].get('id').value) {
      ((this.beachForm.get('beaches') as FormArray).controls[i].get(
        'cabanas',
      ) as FormArray).controls.push(this.createCabana(target.id));
      // }
    } else {
      // if (!((this.poolForm.get('pools') as FormArray).controls[i].get('cabanas') as FormArray).controls.length ||
      //   ((this.poolForm.get('pools') as FormArray).controls[i].get('cabanas') as FormArray)
      //     .controls[((this.poolForm.get('pools') as FormArray)
      //       .controls[i].get('cabanas') as FormArray).controls.length - 1].get('id').value) {
      ((this.poolForm.get('pools') as FormArray).controls[i].get(
        'cabanas',
      ) as FormArray).controls.push(this.createCabana(target.id ? target.id : null));
      // }
    }
  }
  async saveCabana(cabana: FormGroup, field: string, isBeach = false) {
    const cabanaId = cabana.get('id').value;
    if (cabana.valid) {
      if (this.cabanaPoolPhoto || this.cabanaBeachPhoto) {
        if (!isBeach) {
          cabana.value.attachments_to_send.push(this.cabanaPoolPhoto);
          await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.cabanaPoolPhotoThumbnail = reader.result as string;
              resolve();
            };
            reader.readAsDataURL(this.cabanaPoolPhoto);
          });
          cabana.markAsDirty();
          (cabana.get('previewLocalAttachements') as FormArray).push(
            new FormControl(this.cabanaPoolPhotoThumbnail),
          );
        } else {
          cabana.value.attachments_to_send.push(this.cabanaBeachPhoto);
          await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.cabanaBeachPhotoThumbnail = reader.result as string;
              resolve();
            };
            reader.readAsDataURL(this.cabanaBeachPhoto);
          });
          (cabana.get('previewLocalAttachements') as FormArray).push(
            new FormControl(this.cabanaBeachPhotoThumbnail),
          );
          cabana.markAsDirty();
        }
      } else {
        let hasValue = false;
        const uploadData = new FormData();
        uploadData.append(
          'beach_pool_id',
          cabana.get('beach_pool_id').value ? cabana.get('beach_pool_id').value : '',
        );
        if (cabana.get('location').value) {
          hasValue = true;
          uploadData.append('location', cabana.get('location').value);
        }
        uploadData.append(
          'total_cabanas',
          cabana.get('total_cabanas').value
            ? ((hasValue = true), cabana.get('total_cabanas').value)
            : '',
        );
        uploadData.append(
          'scaling_factor',
          cabana.get('scaling_factor').value
            ? ((hasValue = true), cabana.get('scaling_factor').value)
            : '',
        );
        uploadData.append(
          'placement',
          cabana.get('placement').value ? ((hasValue = true), cabana.get('placement').value) : '',
        );

        if (this.cabanaBeachPhoto || this.cabanaPoolPhoto) {
          hasValue = true;
          uploadData.append(
            'attachments[]',
            this.cabanaPoolPhoto ? this.cabanaPoolPhoto : this.cabanaBeachPhoto,
          );
        }
        if (hasValue) {
          this.resortService.createCabana(uploadData).subscribe((res) => {
            if (res.code === 0) {
              if (this.cabanaPoolPhoto || this.cabanaBeachPhoto) {
                (cabana.get('attachments') as FormArray).push(
                  new FormControl(res.data.attachments[res.data.attachments.length - 1].path),
                );
                this.cabanaPoolPhoto = this.cabanaBeachPhoto = null;
              }
              if (isBeach) {
                const cabanaArray = (this.getSingleBeach(
                  cabana.get('beach_pool_id').value,
                ) as FormGroup).get('cabanas') as FormArray;
                cabanaArray.controls[cabanaArray.controls.length - 1]
                  .get('id')
                  .setValue(res.data.id);
              } else {
                const cabanaArray = (this.getSinglePool(
                  cabana.get('beach_pool_id').value,
                ) as FormGroup).get('cabanas') as FormArray;
                cabanaArray.controls[cabanaArray.controls.length - 1]
                  .get('id')
                  .setValue(res.data.id);
              }
              this.updateCabanaDataLocally(res.data, isBeach);
            }
          });
        }
      }
    }
  }
  getSinglePool(poolId): any {
    return (this.poolForm.get('pools') as FormArray).controls.find((elm: FormGroup) => {
      return elm.get('id').value === poolId;
    });
  }
  getSingleBeach(beachId): any {
    return (this.beachForm.get('beaches') as FormArray).controls.find((elm: FormGroup) => {
      return elm.get('id').value === beachId;
    });
  }
  async saveLounge(lounge: FormGroup, field: string, isBeach = false) {
    const loungeId = lounge.get('id').value;
    if (lounge.valid) {
      if (this.loungePoolPhoto || this.loungeBeachPhoto) {
        if (!isBeach) {
          lounge.value.attachments_to_send.push(this.loungePoolPhoto);
          await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.loungePoolPhotoThumbnail = reader.result as string;
              resolve();
            };
            reader.readAsDataURL(this.loungePoolPhoto);
          });
          lounge.markAsDirty();
          (lounge.get('previewLocalAttachements') as FormArray).push(
            new FormControl(this.loungePoolPhotoThumbnail),
          );
        } else {
          lounge.value.attachments_to_send.push(this.loungeBeachPhoto);
          await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              this.loungeBeachPhotoThumbnail = reader.result as string;
              resolve();
            };
            reader.readAsDataURL(this.loungeBeachPhoto);
          });
          (lounge.get('previewLocalAttachements') as FormArray).push(
            new FormControl(this.loungeBeachPhotoThumbnail),
          );
          lounge.markAsDirty();
        }

        this.cdRef.detectChanges();
      } else {
        let hasValue = false;
        const uploadData = new FormData();
        uploadData.append('beach_pool_id', lounge.get('beach_pool_id').value);
        if (lounge.get('location').value) {
          hasValue = true;
          uploadData.append('location', lounge.get('location').value);
        }
        uploadData.append(
          'total_chairs',
          lounge.get('total_chairs').value
            ? ((hasValue = true), lounge.get('total_chairs').value)
            : '',
        );
        uploadData.append(
          'total_rows',
          lounge.get('total_rows').value ? ((hasValue = true), lounge.get('total_rows').value) : '',
        );
        if (this.loungeBeachPhoto || this.loungePoolPhoto) {
          hasValue = true;
          uploadData.append(
            'attachments[]',
            isBeach ? this.loungeBeachPhoto : this.loungePoolPhoto,
          );
        }
        if (hasValue) {
          this.resortService.createLounge(uploadData).subscribe((res) => {
            if (res.code === 0) {
              if (this.loungeBeachPhoto || this.loungePoolPhoto) {
                this.loungeBeachPhoto = this.loungePoolPhoto = null;
                (lounge.get('attachments') as FormArray).push(
                  new FormControl(res.data.attachments[res.data.attachments.length - 1].path),
                );
              }
              this.updateLoungeDataLocally(res.data, isBeach);
              if (isBeach) {
                const loungeArray = (this.getSingleBeach(
                  lounge.get('beach_pool_id').value,
                ) as FormGroup).get('lounges') as FormArray;
                loungeArray.controls[loungeArray.controls.length - 1]
                  .get('id')
                  .setValue(res.data.id);
              } else {
                const loungeArray = (this.getSinglePool(
                  lounge.get('beach_pool_id').value,
                ) as FormGroup).get('lounges') as FormArray;
                loungeArray.controls[loungeArray.controls.length - 1]
                  .get('id')
                  .setValue(res.data.id);
              }
              this.updateLoungeDataLocally(res.data, isBeach);
            }
          });
        }
      }
    }
  }
  addCabana(event, pool, i) {
    if (event.target.checked) {
      ((this.poolForm.get('pools') as FormArray).controls[i].get(
        'cabanas',
      ) as FormArray).controls.push(this.createCabana(pool.id));
    }
  }
  createCabana(poolId?, cabana?) {
    const attachments = new FormArray([]);
    const attachments_to_send = new FormArray([]);
    const previewLocalAttachements = new FormArray([]);

    if (cabana) {
      cabana.attachments.forEach((attachment) => {
        attachments.push(new FormControl(attachment.path));
      });
    }
    const cabanaFormGroup = new FormGroup({
      id: new FormControl(cabana ? cabana.id : ''),
      beach_pool_id: new FormControl(poolId ? poolId : ''),
      total_cabanas: new FormControl(cabana ? cabana.total_cabanas : ''),
      total_rows: new FormControl(cabana ? cabana.total_rows : ''),
      placement: new FormControl(cabana ? cabana.placement : ''),
      scaling_factor: new FormControl(cabana ? cabana.scaling_factor : ''),
      location: new FormControl(cabana ? cabana.location : ''),
      vicinity_map: new FormControl(cabana ? cabana.vicinity_map : ''),
      vicinity_map_preview: new FormControl(cabana ? cabana.vicinity_map_preview : ''),
      vicinity_map_send: new FormControl(cabana ? cabana.vicinity_map_send : ''),
      attachments,
      attachments_to_send,
      previewLocalAttachements,
    });
    const subscription = cabanaFormGroup.valueChanges.subscribe((val) => {
      this.resortService.enableUpdates(true);
      this.enableUpdate = true;
    });
    this.subscribedChangeEvent.push(subscription);
    return cabanaFormGroup;
  }
  createLounge(poolBeachId, lounge?) {
    const attachments = new FormArray([]);
    const attachments_to_send = new FormArray([]);
    const previewLocalAttachements = new FormArray([]);
    if (lounge) {
      lounge.attachments.forEach((attachment) => {
        attachments.push(new FormControl(attachment.path));
      });
    }

    const loungeForm = new FormGroup({
      id: new FormControl(lounge ? lounge.id : ''),
      beach_pool_id: new FormControl(poolBeachId),
      location: new FormControl(lounge ? lounge.location : ''),
      total_chairs: new FormControl(lounge ? lounge.total_chairs : ''),
      total_rows: new FormControl(lounge ? lounge.total_rows : ''),
      placement: new FormControl(lounge ? lounge.placement : ''),
      scaling_factor: new FormControl(lounge ? lounge.scaling_factor : ''),
      vicinity_map: new FormControl(lounge ? lounge.vicinity_map : ''),
      vicinity_map_preview: new FormControl(lounge ? lounge.vicinity_map_preview : ''),
      vicinity_map_send: new FormControl(lounge ? lounge.vicinity_map_send : ''),
      attachments,
      attachments_to_send,
      previewLocalAttachements,
    });
    const subscription = loungeForm.valueChanges.subscribe((val) => {
      this.resortService.enableUpdates(true);
      this.enableUpdate = true;
    });
    this.subscribedChangeEvent.push(subscription);
    return loungeForm;
  }
  get poolFormGroup() {
    return this.poolForm.get('pools') as FormArray;
  }
  saveResort(performApiCount = true) {
    if (this.resortForm.valid) {
      if (this.resort.name) {
        if (
          this.resortForm.get('name').value !== this.resort['name'] ||
          this.resortForm.get('address').value !== this.resort['address'] ||
          this.resortForm.get('telephone').value !== this.resort['telephone'] ||
          this.resortForm.get('email').value !== this.resort['email'] ||
          this.resortForm.get('cabana_full_day_price').value !==
            this.resort['cabana_full_day_price'] ||
          this.resortForm.get('cabana_full_day_price_weekend').value !==
            this.resort['cabana_full_day_price_weekend'] ||
          this.resortForm.get('cabana_full_day_price_special').value !==
            this.resort['cabana_full_day_price_special'] ||
          (this.resortForm.get('cabana_amenities') &&
            this.resortForm.get('cabana_amenities').value !== this.resort.cabana_amenities) ||
          this.resortForm.get('cabana_half_day_price').value !==
            this.resort['cabana_half_day_price'] ||
          this.resortForm.get('lounge_full_day_price').value !==
            this.resort['lounge_full_day_price'] ||
          this.resortForm.get('lounge_full_day_price').value !==
            this.resort['lounge_full_day_price'] ||
          this.whiteLabelFormGroup ||
          typeof this.isWhiteLabelEnabled !== 'undefined' ||
          this.resortForm.get('timezone').value !== this.resort['timezone'] ||
          this.resortVicinityMap ||
          this.resortImageDeleted ||
          this.blackoutDates ||
          this.specialHolidays
        ) {
          const resortData = this.resortForm.value;
          const resortFormData = new FormData();

          if (this.whiteLabelFormGroup) {
            const whitelabelData = this.whiteLabelFormGroup.value;

            // move files from whitelabel JSON data group to it's own file form field
            if (this.whiteLabelFormGroup.controls.logo) {
              resortFormData.append('whitelabel_logo', this.whiteLabelFormGroup.get('logo').value);
            }
            delete whitelabelData.logo;

            if (this.whiteLabelFormGroup.controls.logo_square) {
              resortFormData.append('whitelabel_logo_square', this.whiteLabelFormGroup.get('logo_square').value);
            }
            delete whitelabelData.logo_square;

            // add white label data to form submission
            resortFormData.append('white_label_json', JSON.stringify(whitelabelData));
          }

          // Send the white label state independently
          if (typeof this.isWhiteLabelEnabled !== 'undefined') {
            resortFormData.append('whitelabel_enabled', this.isWhiteLabelEnabled ? '1' : '0');
          }

          resortFormData.append('name', resortData.name);
          resortData.address ? resortFormData.append('address', resortData.address) : null;
          resortData.telephone ? resortFormData.append('telephone', resortData.telephone) : null;
          resortData.email ? resortFormData.append('email', resortData.email) : null;
          resortData.cabana_full_day_price
            ? resortFormData.append('cabana_full_day_price', resortData.cabana_full_day_price)
            : null;
          resortData.cabana_full_day_price_weekend
            ? resortFormData.append(
                'cabana_full_day_price_weekend',
                resortData.cabana_full_day_price_weekend,
              )
            : null;
          resortData.cabana_full_day_price_special
            ? resortFormData.append(
                'cabana_full_day_price_special',
                resortData.cabana_full_day_price_special,
              )
            : null;
          resortData.cabana_amenities
            ? resortFormData.append('cabana_amenities', resortData.cabana_amenities)
            : null;
          resortData.cabana_full_day_price
            ? resortFormData.append('cabana_half_day_price', resortData.cabana_half_day_price)
            : null;

          resortData.lounge_full_day_price
            ? resortFormData.append('lounge_full_day_price', resortData.lounge_full_day_price)
            : null;
          resortData.lounge_half_day_price
            ? resortFormData.append('lounge_half_day_price', resortData.lounge_half_day_price)
            : null;

          resortData.timezone ? resortFormData.append('timezone', resortData.timezone) : null;
          this.blackoutDates
            ? resortFormData.append('blackout_dates', JSON.stringify(this.blackoutDates))
            : null;
          this.specialHolidays
            ? resortFormData.append('special_holiday_dates', JSON.stringify(this.specialHolidays))
            : null;
          this.resortVicinityMap
            ? resortFormData.append('resort_image', this.resortVicinityMap)
            : null;
          if (performApiCount) this.apiCallCount += 1;
          this.resortService.updateResort(this.resort.id, resortFormData).subscribe((res) => {
            if (res.code === 0) {
              this.specialHolidays = this.blackoutDates = null;
              this.resortImageDeleted = false;
              // this.checkForTermAndCondition();
              this.resort = res.data;
              this.userService.updateResort(this.resort);
            }
            if (performApiCount) {
              this.apiCallCount -= 1;
              this.resortService.addApiCount(this.apiCallCount);
            }
          });
        }
      } else {
        const resortData = this.resortForm.value;
        const resortFormData = new FormData();

        resortFormData.append('name', resortData.name);
        resortData.address ? resortFormData.append('address', resortData.address) : null;
        resortData.telephone ? resortFormData.append('telephone', resortData.telephone) : null;
        resortData.email ? resortFormData.append('email', resortData.email) : null;
        resortData.cabana_full_day_price
          ? resortFormData.append('cabana_full_day_price', resortData.cabana_full_day_price)
          : null;
        resortData.cabana_full_day_price_weekend
          ? resortFormData.append(
              'cabana_full_day_price_weekend',
              resortData.cabana_full_day_price_weekend,
            )
          : null;
        resortData.cabana_full_day_price_special
          ? resortFormData.append(
              'cabana_full_day_price_special',
              resortData.cabana_full_day_price_special,
            )
          : null;
        resortData.cabana_amenities
          ? resortFormData.append('cabana_amenities', resortData.cabana_amenities)
          : null;
        resortData.cabana_full_day_price
          ? resortFormData.append('cabana_half_day_price', resortData.cabana_half_day_price)
          : null;
        resortData.timezone ? resortFormData.append('timezone', resortData.timezone) : null;
        this.blackoutDates
          ? resortFormData.append('blackout_dates', resortData.blackout_dates)
          : null;
        this.specialHolidays
          ? resortFormData.append('special_holiday_dates', resortData.special_holiday_dates)
          : null;
        this.resortVicinityMap
          ? resortFormData.append('resort_image', this.resortVicinityMap)
          : null;

        if (performApiCount) this.apiCallCount += 1;
        this.resortService.createResort(resortFormData).subscribe((res) => {
          if (res.code === 0) {
            this.specialHolidays = this.blackoutDates = null;
            // this.checkForTermAndCondition();
            this.resort = res.data;
            this.userService.updateResort(this.resort);
          }
          if (performApiCount) {
            this.apiCallCount -= 1;
            this.resortService.addApiCount(this.apiCallCount);
          }
        });
      }
    }
  }
  getPoolFromGroup(index) {
    return this.poolList.controls[index] as FormGroup;
  }
  getPoolById(id) {
    return this.pools.find((o) => o.id === id);
  }
  getBeachById(id) {
    return this.beaches.find((o) => o.id === id);
  }
  getCabanaById(id, beachPoolId, isBeach = false) {
    if (isBeach) {
      const beach = this.getBeachById(beachPoolId);
      return beach.cabanas.find((o) => o.id === id);
    } else {
      const pool = this.getPoolById(beachPoolId);
      return pool.cabanas.find((o) => o.id === id);
    }
  }
  getLoungeById(id, beachPoolId, isBeach = false) {
    if (isBeach) {
      const beach = this.getBeachById(beachPoolId);
      return beach.lounges.find((o) => o.id == id);
    } else {
      const pool = this.getPoolById(beachPoolId);
      return pool.lounges.find((o) => o.id == id);
    }
  }

  savePool(pool: FormGroup, field: string) {
    if (pool.valid) {
      const poolId = pool.get('id').value;
      if (poolId) {
        if (this.poolPhoto) {
          const uploadData = new FormData();
          uploadData.append('display_image', this.poolPhoto);
          this.poolPhoto = this.poolPhotoThumbnail = null;
          this.resortService.updatePool(poolId, uploadData).subscribe((res) => {
            if (res.code === 0) {
              // this.checkForTermAndCondition();
              this.updatePoolDataLocally(res.data);
            }
          });
        } else if (this.poolVicinitymap) {
          const uploadData = new FormData();
          uploadData.append('vicinity_map', this.poolVicinitymap);
          this.poolVicinitymap = this.poolVicinitymapThumbnail = null;
          this.resortService.updatePool(poolId, uploadData).subscribe((res) => {
            if (res.code === 0) {
              // this.checkForTermAndCondition();
              this.updatePoolDataLocally(res.data);
            }
          });
        } else if (this.poolLayoutImage) {
          const uploadData = new FormData();
          uploadData.append('layout', this.poolLayoutImage);
          this.poolLayoutImage = this.poolLayoutImageThumbnail = null;
          this.resortService.updatePool(poolId, uploadData).subscribe((res) => {
            if (res.code === 0) {
              // this.checkForTermAndCondition();
              this.updatePoolDataLocally(res.data);
            }
          });
        } else {
          const originalValue = this.getPoolById(poolId);
          const tempUserPool = {
            name: pool.get('name').value,
            description: pool.get('description').value,
            latitude: pool.get('latitude').value,
            longitude: pool.get('longitude').value,
          };
          if (pool.get(field).value && pool.get(field).value !== originalValue[field]) {
            this.resortService.updatePool(poolId, tempUserPool).subscribe((res) => {
              if (res.code === 0) {
                // this.checkForTermAndCondition();
                this.updatePoolDataLocally(res.data);
              }
            });
          }
        }
      } else {
        this.resortService.createPool(pool.value).subscribe((res) => {
          if (res.code === 0) {
            // this.checkForTermAndCondition();
            this.newPools.push(res.data.id);
            (this.poolForm.get('pools') as FormArray).controls[
              (this.poolForm.get('pools') as FormArray).controls.length - 1
            ]
              .get('id')
              .setValue(res.data.id);
            this.updatePoolDataLocally(res.data);
          }
        });
        this.poolVicinitymap = null;
      }
    }
  }

  saveBeach(beach: FormGroup, field: string) {
    if (beach.valid) {
      const beachId = beach.get('id').value;
      if (beachId) {
        if (this.beachPhoto) {
          const uploadData = new FormData();
          uploadData.append('display_image', this.beachPhoto);
          this.beachPhoto = this.beachPhotoThumbnail = null;
          this.resortService.updateBeach(beachId, uploadData).subscribe((res) => {
            if (res.code === 0) {
              // this.checkForTermAndCondition();
              this.updateBeachDataLocally(res.data);
            }
          });
        } else if (this.beachVicinitymap) {
          const uploadData = new FormData();
          uploadData.append('display_image', this.beachVicinitymap);
          this.resortService.updateBeach(beachId, uploadData).subscribe((res) => {
            if (res.code === 0) {
              // this.checkForTermAndCondition();
              this.updateBeachDataLocally(res.data);
            }
          });
        } else if (this.beachLayoutImage) {
          const uploadData = new FormData();
          uploadData.append('layout', this.beachLayoutImage);
          this.beachLayoutImage = this.beachLayoutImageThumbnail = null;
          this.resortService.updateBeach(beachId, uploadData).subscribe((res) => {
            if (res.code === 0) {
              // this.checkForTermAndCondition();
              this.updateBeachDataLocally(res.data);
            }
          });
        } else {
          const originalValue = this.getBeachById(beachId);

          const tempUserBeach = {
            name: beach.get('name').value,
            description: beach.get('description').value,
            latitude: beach.get('latitude').value,
            longitude: beach.get('longitude').value,
          };

          if (beach.get(field).value && beach.get(field).value !== originalValue[field]) {
            this.resortService.updateBeach(beachId, tempUserBeach).subscribe((res) => {
              if (res.code === 0) {
                // this.checkForTermAndCondition();
                this.updateBeachDataLocally(res.data);
              }
            });
          }
        }
      } else {
        this.resortService.createBeach(beach.value).subscribe((res) => {
          if (res.code === 0) {
            // this.checkForTermAndCondition();
            this.newBeaches.push(res.data.id);
            (this.beachForm.get('beaches') as FormArray).controls[
              (this.beachForm.get('beaches') as FormArray).controls.length - 1
            ]
              .get('id')
              .setValue(res.data.id);
            this.updateBeachDataLocally(res.data);
          }
        });
        this.beachVicinitymap = null;
      }
    }
  }
  addPoolDataLocally(pool) {}
  updatePoolDataLocally(pool) {
    const tempPools = this.pools.filter((element) => {
      return element.id !== pool.id;
    });
    tempPools.push(pool);
    this.pools = tempPools;
    this.userService.updatePool(this.pools);
    this.cdRef.detectChanges();
  }
  updateBeachDataLocally(beach) {
    const tempBeaches = this.beaches.filter((element) => {
      return element.id !== beach.id;
    });
    tempBeaches.push(beach);
    this.beaches = tempBeaches;
    this.userService.updateBeach(this.beaches);
    this.cdRef.detectChanges();
  }
  updateCabanaDataLocally(cabana, beach = false) {
    if (beach) {
      const tempBeach = this.getBeachById(+cabana.beach_pool_id);
      const tempCabanas = tempBeach.cabanas;
      const filteredCabanas = tempCabanas.filter((elm) => {
        return elm.id !== cabana.id;
      });
      filteredCabanas.push(cabana);
      tempBeach.cabanas = filteredCabanas;
      this.updateBeachDataLocally(tempBeach);
    } else {
      const tempPool = this.getPoolById(+cabana.beach_pool_id);
      const tempCabanas = tempPool.cabanas;
      const filteredCabanas = tempCabanas.filter((elm) => {
        return elm.id !== cabana.id;
      });
      filteredCabanas.push(cabana);
      tempPool.cabanas = filteredCabanas;
      this.updatePoolDataLocally(tempPool);
    }
  }
  updateLoungeDataLocally(lounge, beach = false) {
    if (beach) {
      const tempBeach = this.getBeachById(+lounge.beach_pool_id);
      const tempLounges = tempBeach.lounges;
      const filteredLounges = tempLounges.filter((elm) => {
        return elm.id !== lounge.id;
      });
      filteredLounges.push(lounge);
      tempBeach.lounges = filteredLounges;
      this.updateBeachDataLocally(tempBeach);
    } else {
      const tempPool = this.getPoolById(+lounge.beach_pool_id);
      const tempLounges = tempPool.lounges;
      const filteredLounges = tempLounges.filter((elm) => {
        return elm.id !== lounge.id;
      });
      filteredLounges.push(lounge);
      tempPool.lounges = filteredLounges;
      this.updatePoolDataLocally(tempPool);
    }
  }
  uploadImage(event, target: FormGroup, type) {
    if (event.target.files.length > 0) {
      switch (type) {
        case 'cabanaBeach': {
          this.cabanaBeachPhoto = event.target.files[0];
          const reader = new FileReader();
          reader.onload = () => {
            this.cabanaBeachPhotoThumbnail = reader.result as string;
          };
          reader.readAsDataURL(this.cabanaBeachPhoto);
          this.saveCabana(target, null, true);
          break;
        }
        case 'loungeBeach': {
          this.loungeBeachPhoto = event.target.files[0];
          const reader = new FileReader();
          reader.onload = () => {
            this.loungeBeachPhotoThumbnail = reader.result as string;
          };
          reader.readAsDataURL(this.loungeBeachPhoto);

          this.saveLounge(target, null, true);
          break;
        }
        case 'cabanaPool': {
          this.cabanaPoolPhoto = event.target.files[0];
          const reader = new FileReader();
          reader.onload = () => {
            this.cabanaPoolPhotoThumbnail = reader.result as string;
          };
          reader.readAsDataURL(this.cabanaPoolPhoto);
          this.saveCabana(target, null);
          break;
        }
        case 'loungePool': {
          this.loungePoolPhoto = event.target.files[0];
          const reader = new FileReader();
          reader.onload = () => {
            this.loungePoolPhotoThumbnail = reader.result as string;
          };
          reader.readAsDataURL(this.loungePoolPhoto);
          this.saveLounge(target, null);
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  locationChanged(event, target: FormGroup) {
    target.get('location').setValue(event.target.value);
    target.markAsDirty();
  }
  additionalAmenitiesChange(event) {
    if (event.target.checked && !this.resortForm.get('cabana_amenities')) {
      this.resortForm.addControl('cabana_amenities', new FormControl(''));
    }
  }
  onCanvasClear() {}

  saveMapForCabana(canvasWhiteboard: CanvasWhiteboardComponent, pool: FormGroup) {
    canvasWhiteboard.generateCanvasBlob((blob: any) => {
      const formdata = new FormData();
      formdata.append('vicinity_map', blob);
      this.resortService.updatePool(pool.get('id').value, formdata).subscribe((res) => {});
    }, 'image/jpg');
  }
  saveEditedMap(canvasWhiteboard: CanvasWhiteboardComponent, pool: FormGroup) {
    canvasWhiteboard.generateCanvasBlob((blob: any) => {
      this.canvasOptions.imageUrl = blob;
    });
  }

  loadModal(beachPoolId, cabanaLounge: FormGroup, type: string, isBeach = false) {
    const modalRef = this.modalServices.open(MapEditModalComponent, { size: 'lg' });

    if (type === 'cabanaMap' || type === 'loungeMap') {
      let cabanaLoungeValue = cabanaLounge.value;
      cabanaLoungeValue.id
        ? cabanaLoungeValue.vicinity_map
          ? (modalRef.componentInstance.imageExists = true)
          : cabanaLoungeValue.vicinity_map_preview
          ? (modalRef.componentInstance.imageExists = true)
          : (modalRef.componentInstance.imageExists = false)
        : (modalRef.componentInstance.imageExists = false);

      modalRef.componentInstance.target = cabanaLoungeValue.vicinity_map
        ? cabanaLoungeValue
        : { vicinity_map: cabanaLoungeValue.vicinity_map_preview };

      if (!cabanaLoungeValue.id) {
        if (cabanaLoungeValue.vicinity_map_preview) {
          modalRef.componentInstance.imageExists = true;
          let abc = {
            vicinity_map: cabanaLoungeValue.vicinity_map_preview,
          };
          modalRef.componentInstance.target = abc;
        } else {
          modalRef.componentInstance.imageExists = false;
        }
      }

      modalRef.componentInstance.type = type;
      modalRef.componentInstance.isBeach = isBeach;

      modalRef.componentInstance.dataUpdated.subscribe((res) => {
        cabanaLounge.markAsDirty();

        this.loungeVicinityMapThumbnail = res.dataUrl;
        (cabanaLounge.get('vicinity_map_send') as FormControl).setValue(res.blob);
        (cabanaLounge.get('vicinity_map_preview') as FormControl).setValue(res.dataUrl);

        // (cabanaLounge.get('vicinity_map_preview') as FormControl).setValue(res.dataUrl);
        //  const reader = new FileReader();
        //  reader.onload = () => {
        //    this.loungeVicinityMapThumbnail = reader.result as string;
        //   //  this.resort.resort_image = this.resortVicinityMapThumbnail;
        //  };
        //  reader.readAsDataURL(res);
        //  reader.readAsDataURL(res);
        //  reader.readAsDataURL(res);
        //  reader.readAsDataURL(res);
        //  reader.readAsDataURL(res);
        //  this.cdRef.detectChanges();
        // if (type === 'cabanaMap') {
        //   if (!cabanaLounge.id) {
        //     if (isBeach) {
        //       const cabanaArray = (this.getSingleBeach(beachPoolId) as FormGroup).get('cabanas') as FormArray;
        //       cabanaArray.controls[cabanaArray.controls.length - 1].get('id').setValue(res.id);
        //     } else {
        //       const cabanaArray = (this.getSinglePool(beachPoolId) as FormGroup).get('cabanas') as FormArray;
        //       cabanaArray.controls[cabanaArray.controls.length - 1].get('id').setValue(res.id);
        //     }
        //   }
        //   this.updateCabanaDataLocally(res, isBeach);
        // } else {
        //   if (!cabanaLounge.id) {
        //     if (isBeach) {
        //       const loungeArray = (this.getSingleBeach(beachPoolId) as FormGroup).get('lounges') as FormArray;
        //       loungeArray.controls[loungeArray.controls.length - 1].get('id').setValue(res.id);
        //     } else {
        //       const loungeArray = (this.getSinglePool(beachPoolId) as FormGroup).get('lounges') as FormArray;
        //       loungeArray.controls[loungeArray.controls.length - 1].get('id').setValue(res.id);
        //     }
        //   }
        //   this.updateLoungeDataLocally(res.data, isBeach);

        // }
      });
      modalRef.componentInstance.photoDeleted.subscribe((data) => {
        cabanaLounge.markAsDirty();

        if (!cabanaLoungeValue.vicinity_map_send) {
          if (type === 'cabanaMap') {
            cabanaLoungeValue.vicinity_map = null;
            (cabanaLounge.get('vicinity_map') as FormControl).setValue(null);
            this.updateCabanaDataLocally(cabanaLoungeValue, isBeach);
          } else {
            // let lounge = this.getLoungeById(cabanaLoungeValue.get('id'), beachPoolId, isBeach)
            cabanaLoungeValue.vicinity_map = null;
            (cabanaLounge.get('vicinity_map') as FormControl).setValue(null);
            this.updateLoungeDataLocally(cabanaLoungeValue, isBeach);
          }
        } else {
          (cabanaLounge.get('vicinity_map_preview') as FormControl).setValue(null);
          (cabanaLounge.get('vicinity_map_send') as FormControl).setValue(null);
        }
      });
      // }
      // else {
      //   console.log(cabanaLounge.id, type);
      //   let abc;
      //   if (this.loungeVicinityMapThumbnail) {

      //     modalRef.componentInstance.imageExists = true;
      //     abc = {
      //       vicinity_map: this.loungeVicinityMapThumbnail
      //     }
      //   }
      //   modalRef.componentInstance.target = cabanaLounge.id ? this.getLoungeById(cabanaLounge.id, beachPoolId, isBeach) : abc;
      //   console.log(modalRef.componentInstance.target);

      // }
    } else if (type === 'resort') {
      if (!this.resort) {
        this.resort = {
          activated_at: null,
          address: null,
          beaches: [],
          blackout_dates: [],
          cabana_amenities: null,
          cabana_full_day_price: '',
          cabana_full_day_price_special: '',
          cabana_full_day_price_weekend: '',
          cabana_half_day_price: '',
          email: null,
          id: '',
          lounge_full_day_price: '',
          lounge_half_day_price: '',
          name: null,
          pools: [],
          resort_image: null,
          special_holiday_dates: [],
          telephone: null,
          timezone: null,
        };
      }

      this.resort
        ? this.resort.resort_image || this.resortVicinityMap
          ? (modalRef.componentInstance.imageExists = true)
          : (modalRef.componentInstance.imageExists = false)
        : (modalRef.componentInstance.imageExists = false);
      modalRef.componentInstance.target = this.resort ? this.resort : null;
      modalRef.componentInstance.type = type;
      modalRef.componentInstance.dataUpdated.subscribe((res) => {
        this.resortVicinityMap = res;
        const reader = new FileReader();
        reader.onload = () => {
          this.resortVicinityMapThumbnail = reader.result as string;
          this.resort.resort_image = this.resortVicinityMapThumbnail;
        };
        reader.readAsDataURL(this.resortVicinityMap);
      });
      modalRef.componentInstance.photoDeleted.subscribe((res) => {
        this.resortImageDeleted = true;
        this.resort.resort_image = null;
        this.resortVicinityMap = this.resortVicinityMapThumbnail = null;
      });
    } else if (type == 'layout') {
      let poolValue = cabanaLounge.value;

      poolValue.id
        ? poolValue.layout
          ? (modalRef.componentInstance.imageExists = true)
          : poolValue.layout_preview
          ? (modalRef.componentInstance.imageExists = true)
          : (modalRef.componentInstance.imageExists = false)
        : poolValue.layout_preview
        ? (modalRef.componentInstance.imageExists = true)
        : (modalRef.componentInstance.imageExists = false);

      modalRef.componentInstance.type = type;
      modalRef.componentInstance.target = poolValue.layout
        ? poolValue
        : { layout: poolValue.layout_preview };

      // if (!poolValue.id) {
      //   if (poolValue.layout_preview) {
      //     modalRef.componentInstance.imageExists = true;
      //     let abc = {
      //       layout: poolValue.vicinity_map_preview
      //     }
      //     modalRef.componentInstance.target = abc;
      //   }
      //   else {
      //     modalRef.componentInstance.imageExists = false;
      //   }
      // }
      modalRef.componentInstance.dataUpdated.subscribe((res) => {
        this.poolLayoutImage = res;
        const reader = new FileReader();
        reader.onload = () => {
          this.poolLayoutImageThumbnail = reader.result as string;
          (cabanaLounge.get('layout_preview') as FormControl).setValue(
            this.poolLayoutImageThumbnail,
          );
          this.cdRef.detectChanges();
        };
        reader.readAsDataURL(this.poolLayoutImage);

        (cabanaLounge.get('layout_send') as FormControl).setValue(this.poolLayoutImage);
      });

      modalRef.componentInstance.photoDeleted.subscribe((res) => {
        if (!poolValue.layout_preview) {
          cabanaLounge.value.layout = null;
          (cabanaLounge.get('layout') as FormControl).setValue(null);
          if (!isBeach) {
            this.updatePoolDataLocally(cabanaLounge.value);
          } else {
            this.updateBeachDataLocally(cabanaLounge.value);
            this.cdRef.detectChanges();
            console.log(this.getPoolById(beachPoolId));
          }
        } else {
          (cabanaLounge.get('layout_preview') as FormControl).setValue(null);
          (cabanaLounge.get('layout_send') as FormControl).setValue(null);
        }
      });
    } else if (isBeach) {
      beachPoolId
        ? this.getBeachById(beachPoolId).layout
          ? (modalRef.componentInstance.imageExists = true)
          : (modalRef.componentInstance.imageExists = false)
        : (modalRef.componentInstance.imageExists = false);
      modalRef.componentInstance.target = beachPoolId ? this.getBeachById(beachPoolId) : null;
      modalRef.componentInstance.type = type;
      modalRef.componentInstance.isBeach = isBeach;
      modalRef.componentInstance.dataUpdated.subscribe((res) => {
        this.updateBeachDataLocally(res);
        if (!beachPoolId) {
          (this.beachForm.get('beaches') as FormArray).controls[
            (this.beachForm.get('beaches') as FormArray).controls.length - 1
          ]
            .get('id')
            .setValue(res.id);
        }
      });
      modalRef.componentInstance.photoDeleted.subscribe((data) => {
        this.updateBeachDataLocally(data);
      });
    } else {
      beachPoolId
        ? this.getPoolById(beachPoolId).layout
          ? (modalRef.componentInstance.imageExists = true)
          : (modalRef.componentInstance.imageExists = false)
        : (modalRef.componentInstance.imageExists = false);
      modalRef.componentInstance.target = beachPoolId ? this.getPoolById(beachPoolId) : null;

      modalRef.componentInstance.isBeach = isBeach;
      modalRef.componentInstance.type = type;
      modalRef.componentInstance.dataUpdated.subscribe((res) => {
        this.updatePoolDataLocally(res);
        if (!beachPoolId) {
          (this.poolForm.get('pools') as FormArray).controls[
            (this.poolForm.get('pools') as FormArray).controls.length - 1
          ]
            .get('id')
            .setValue(res.id);
        }
      });
      modalRef.componentInstance.photoDeleted.subscribe((data) => {
        this.updatePoolDataLocally(data);
      });
    }
  }
  openMessageModal(modalName) {
    this.modalServices.open(modalName, { size: 'xl' });
  }
  deleteBeach(id, index) {
    if (confirm('Are you sure you want to delete beach from your resort?')) {
      if (id) {
        this.resortService.deleteBeach(id).subscribe((res) => {
          if (res.code === 0) {
            (this.beachForm.get('beaches') as FormArray).removeAt(index);
            this.beaches = this.beaches.filter((o) => o.id !== id);
          }
        });
      } else {
        (this.beachForm.get('beaches') as FormArray).removeAt(index);
      }
    }
  }
  deletePool(index, id?) {
    if (confirm('Are you sure you want to delete pool from your resort?')) {
      if (id) {
        this.resortService.deletePool(id).subscribe((res) => {
          if (res.code === 0) {
            (this.poolForm.get('pools') as FormArray).removeAt(index);
            this.pools = this.pools.filter((o) => o.id !== id);
          }
        });
      } else {
        (this.poolForm.get('pools') as FormArray).removeAt(index);
      }
    }
  }
  deleteLoungeAttachment(beachPoolId, lounge: FormGroup, indexOfAttachment, isBeach = false) {
    if (confirm('Are you sure you want to delete lounge area image?')) {
      this.resortService
        .deleteAttachments(
          this.getLoungeById(lounge.get('id').value, beachPoolId, isBeach).attachments[
            indexOfAttachment
          ].id,
        )
        .subscribe((res) => {
          if (res.code === 0) {
            const currentLounge = this.getLoungeById(lounge.get('id').value, beachPoolId, isBeach);
            currentLounge.attachments.splice(indexOfAttachment, 1);
            this.updateLoungeDataLocally(currentLounge, isBeach);
            (lounge.get('attachments') as FormArray).removeAt(indexOfAttachment);
          }
        });
    }
  }
  deleteCabanaAttachment(beachPoolId, cabana: FormGroup, indexOfAttachment, isBeach = false) {
    if (confirm('Are you sure you want to delete cabana image?')) {
      this.resortService
        .deleteAttachments(
          this.getCabanaById(cabana.get('id').value, beachPoolId, isBeach).attachments[
            indexOfAttachment
          ].id,
        )
        .subscribe((res) => {
          if (res.code === 0) {
            const currentCabana = this.getCabanaById(cabana.get('id').value, beachPoolId, isBeach);
            currentCabana.attachments.splice(indexOfAttachment, 1);
            this.updateCabanaDataLocally(currentCabana, isBeach);
            (cabana.get('attachments') as FormArray).removeAt(indexOfAttachment);
          }
        });
    }
  }
  deleteLoungeArea(loungeId, beachorPoolform: FormGroup, loungeIndex, isBeach = false) {
    if (confirm('Are you sure you want to delete Lounge from your resort?')) {
      if (loungeId) {
        this.resortService.deleteLoungeArea(loungeId).subscribe((res) => {
          if (res.code === 0) {
            if (isBeach) {
              (beachorPoolform.get('lounges') as FormArray).removeAt(loungeIndex);
              const tempBeach = this.getBeachById(beachorPoolform.get('id').value);
              tempBeach.lounges = tempBeach.lounges.filter((lounge) => lounge.id !== loungeId);
              this.updateBeachDataLocally(tempBeach);
            } else {
              (beachorPoolform.get('lounges') as FormArray).removeAt(loungeIndex);
              const tempPool = this.getPoolById(beachorPoolform.get('id').value);
              tempPool.lounges = tempPool.lounges.filter((lounge) => lounge.id !== loungeId);
              this.updatePoolDataLocally(tempPool);
            }
          }
        });
      } else {
        (beachorPoolform.get('lounges') as FormArray).removeAt(loungeIndex);
      }
    }
  }
  deleteCabana(cabanaId, beachorPoolform: FormGroup, cabanaIndex, isBeach = false) {
    if (confirm('Are you sure you want to delete cabana from your resort?')) {
      if (cabanaId) {
        this.resortService.deleteCabana(cabanaId).subscribe((res) => {
          if (res.code === 0) {
            if (isBeach) {
              (beachorPoolform.get('cabanas') as FormArray).removeAt(cabanaIndex);
              const tempBeach = this.getBeachById(beachorPoolform.get('id').value);
              tempBeach.cabanas = tempBeach.cabanas.filter((cabana) => cabana.id !== cabanaId);
              this.updateBeachDataLocally(tempBeach);
            } else {
              (beachorPoolform.get('cabanas') as FormArray).removeAt(cabanaIndex);
              const tempPool = this.getPoolById(beachorPoolform.get('id').value);
              tempPool.cabanas = tempPool.cabanas.filter((lounge) => lounge.id !== cabanaId);
              this.updatePoolDataLocally(tempPool);
            }
          }
        });
      } else {
        (beachorPoolform.get('cabanas') as FormArray).removeAt(cabanaIndex);
      }
    }
  }
  activateSolay() {
    this.router.navigate(['solay/term-and-contition-service']);
  }
  onDateSelect(event, picker: MatCalendar<Date>, isBlackout = false) {
    if (this.resort) {
      if (isBlackout) {
        this.blackoutDates = this.resort ? this.resort.blackout_dates : [];
        for (const d of this.blackoutDates) {
          if (d == this.formatDate(event)) {
            return;
          }
        }
        this.blackoutDates.push(this.formatDate(event));
        // this.resort.blackout_dates.push(this.formatDate(event))
        picker.updateTodaysDate();
      } else {
        this.specialHolidays = this.resort ? this.resort.special_holiday_dates : [];
        for (const d of this.specialHolidays) {
          if (d == this.formatDate(event)) {
            return;
          }
        }
        this.specialHolidays.push(this.formatDate(event));
        // this.resort.special_holiday_dates.push(this.formatDate(event))
        picker.updateTodaysDate();
      }
      this.resortService.enableUpdates(true);
      this.enableUpdate = true;
    }
  }
  onDateDelete(date, picker: MatCalendar<Date>, isBlackout = false) {
    if (isBlackout) {
      this.resort.blackout_dates = this.resort.blackout_dates.filter((d) => {
        return date != d;
      });
      this.blackoutDates = this.resort.blackout_dates;
      picker.updateTodaysDate();
    } else {
      this.resort.special_holiday_dates = this.resort.special_holiday_dates.filter((d) => {
        return date != d;
      });
      this.specialHolidays = this.resort.special_holiday_dates;
      picker.updateTodaysDate();
    }
    this.resortService.enableUpdates(true);
    this.enableUpdate = true;
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
      if (day.length < 2) {
        day = '0' + day;
      }
    }

    return [year, month, day].join('-');
  }
  setDate(dates) {
    return dates.map((m) => new Date(m));
  }
  blackoutDateClass = (date: Date): MatCalendarCellCssClasses => {
    if (this.resort) {
      const highlightDate = this.resort.blackout_dates
        .map((strDate) => strDate)
        .some((d) => {
          d = d.split('-');

          return (
            d[2] == date.getDate() && d[1] == date.getMonth() + 1 && d[0] == date.getFullYear()
          );
        });
      return highlightDate ? 'example-custom-date-class' : '';
    } else {
      return '';
    }
  };
  specialDateClass = (date: Date): MatCalendarCellCssClasses => {
    if (this.resort) {
      const highlightDate = this.resort.special_holiday_dates
        .map((strDate) => strDate)
        .some((d) => {
          d = d.split('-');

          return (
            d[2] == date.getDate() && d[1] == date.getMonth() + 1 && d[0] == date.getFullYear()
          );
        });
      return highlightDate ? 'example-custom-date-class' : '';
    } else {
      return '';
    }
  };

  get sortedBlacklistDate() {
    if (this.resort) {
      return this.resort.blackout_dates.sort((a, b) => {
        return (new Date(a) as any) - (new Date(b) as any);
      });
    } else {
      return null;
    }
  }
  get sortedSpecialHolidayDate() {
    if (this.resort) {
      return this.resort.special_holiday_dates.sort((a, b) => {
        return (new Date(a) as any) - (new Date(b) as any);
      });
    } else {
      return null;
    }
  }
  checkForTermAndCondition() {
    // const lastSave = localStorage.getItem('lastSave');
    // if (!(lastSave && lastSave === new Date().getDate().toString() + this.resort.id)) {
    this.modalServices.open(this.termsModal, { size: 'xl', backdrop: 'static', keyboard: false });
    // }
  }
  setTerms(event) {
    if (event.target.checked) {
      this.termsAccepted = true;
    } else {
      this.termsAccepted = false;
    }
  }
  acceptTerms() {
    if (this.termsAccepted) {
      this.termsAccepted = false;
      // const dateStr = new Date().getDate().toString();
      // localStorage.setItem('lastSave', dateStr + this.resort.id);
      // this.modalServices.dismissAll();
      this.loading = true;
      this.finalSave();
    }
  }
  closeModal() {
    this.modalServices.dismissAll();
  }
  finalSave() {
    this.saveResort();
    this.saveAllPool();
    this.saveAllBeach();
    this.resortService
      .updateResort(this.resort.id, { activate: this.isSuperAdmin ? 1 : 0 })
      .subscribe((response: any) => console.log(response));
    if (this.apiCallCount == 0) {
      this.resortService.addApiCount(0);
    }
  }

  saveAllBeach() {
    this.enableUpdate = true;

    const { resortAreas } = store.getState();
    const allBeaches = resortAreas.filter((resortArea: ResortArea) => resortArea.type === 'beach');

    // Delete beaches that have been removed
    const deletedBeaches = [];
    allBeaches.forEach((beach: ResortArea) => {
      if (beach.isDeleted === true) {
        deletedBeaches.push(beach.id);
      }
    });

    deletedBeaches.forEach((id: number) => {
      this.resortService.deleteBeach(id).subscribe((response: any) => console.log(response));
    });

    // Create new beaches that have been added and update existing beaches that have been changed
    allBeaches.forEach((beach: ResortArea) => {
      const isNewBeach = beach.id === undefined;

      const hasCabanas =
        beach.items.filter((item: ResortAreaItem) => item.type === 'cabana').length > 0;

      const hasChairs =
        beach.items.filter((item: ResortAreaItem) => item.type === 'chair').length > 0;

      let beachFormData = new FormData();
      beachFormData.append('resort_id', this.resort.id);
      beachFormData.append('cabana_reservation', hasCabanas ? '1' : '0');
      beachFormData.append('lounge_reservation', hasChairs ? '1' : '0');
      beachFormData.append('name', beach.name);
      beachFormData.append('description', beach.description);
      beachFormData.append('latitude', beach.latitude.toString());
      beachFormData.append('longitude', beach.longitude.toString());
      if (beach.layoutFile) {
        beachFormData.append('layout', beach.layoutFile);
      }
      beachFormData.append('scale', beach.scale.toString());
      beachFormData.append('items', JSON.stringify(beach.items));
      if (beach.attachmentsFiles) {
        beach.attachmentsFiles.forEach((file: File) => {
          beachFormData.append('attachments[]', file);
        });
      }

      if (isNewBeach) {
        this.resortService;
        this.resortService
          .createBeach(beachFormData)
          .subscribe((response: any) => console.log(response));
      } else {
        this.resortService
          .updateBeach(beach.id, beachFormData)
          .subscribe((response: any) => console.log(response));

        if (beach.discardedAttachments && beach.discardedAttachments.length > 0) {
          beach.discardedAttachments.forEach((discardedAttachmentId: number) => {
            this.resortService
              .deleteAttachments(discardedAttachmentId)
              .subscribe((response: any) => console.log(response));
          });
        }

        // @TODO remove this dummy lounge
        this.resortService
          .createLounge({
            id: 0,
            beach_pool_id: beach.id,
            total_chairs: 100,
            total_rows: 1,
          })
          .subscribe((response: any) => console.log(response));

        // @TODO remove this dummy cabana
        this.resortService
          .createCabana({
            id: 0,
            beach_pool_id: beach.id,
            total_chairs: 100,
            total_rows: 1,
          })
          .subscribe((response: any) => console.log(response));
      }
    });
  }

  saveAllPool() {
    this.enableUpdate = true;

    const { resortAreas } = store.getState();
    const allPools = resortAreas.filter((resortArea: ResortArea) => resortArea.type === 'pool');

    // Delete pools that have been removed
    const deletedPools = [];
    allPools.forEach((pool: ResortArea) => {
      if (pool.isDeleted === true) {
        deletedPools.push(pool.id);
      }
    });

    deletedPools.forEach((id: number) => {
      this.resortService.deletePool(id).subscribe((response: any) => console.log(response));
    });

    // Create new pools that have been added and update existing pools that have been changed
    allPools.forEach((pool: ResortArea) => {
      const isNewPool = pool.id === undefined;

      const hasCabanas =
        pool.items.filter((item: ResortAreaItem) => item.type === 'cabana').length > 0;

      const hasChairs =
        pool.items.filter((item: ResortAreaItem) => item.type === 'chair').length > 0;

      let poolFormData = new FormData();
      poolFormData.append('resort_id', this.resort.id);
      poolFormData.append('cabana_reservation', hasCabanas ? '1' : '0');
      poolFormData.append('lounge_reservation', hasChairs ? '1' : '0');
      poolFormData.append('name', pool.name);
      poolFormData.append('description', pool.description);
      poolFormData.append('latitude', pool.latitude.toString());
      poolFormData.append('longitude', pool.longitude.toString());
      if (pool.layoutFile) {
        poolFormData.append('layout', pool.layoutFile);
      }
      poolFormData.append('scale', pool.scale.toString());
      poolFormData.append('items', JSON.stringify(pool.items));
      if (pool.attachmentsFiles) {
        pool.attachmentsFiles.forEach((file: File) => {
          poolFormData.append('attachments[]', file);
        });
      }

      if (isNewPool) {
        this.resortService
          .createPool(poolFormData)
          .subscribe((response: any) => console.log(response));
      } else {
        this.resortService
          .updatePool(pool.id, poolFormData)
          .subscribe((response: any) => console.log(response));

        if (pool.discardedAttachments && pool.discardedAttachments.length > 0) {
          pool.discardedAttachments.forEach((discardedAttachmentId: number) => {
            this.resortService
              .deleteAttachments(discardedAttachmentId)
              .subscribe((response: any) => console.log(response));
          });
        }

        // @TODO remove this dummy lounge
        this.resortService
          .createLounge({
            id: 0,
            beach_pool_id: pool.id,
            total_chairs: 100,
            total_rows: 1,
          })
          .subscribe((response: any) => console.log(response));

        // @TODO remove this dummy cabana
        this.resortService
          .createCabana({
            id: 0,
            beach_pool_id: pool.id,
            total_chairs: 100,
            total_rows: 1,
          })
          .subscribe((response: any) => console.log(response));
      }
    });
  }

  private isCabanaValid(cabana: FormGroup) {
    if (
      cabana.get('total_cabanas').value ||
      cabana.get('total_rows').value ||
      cabana.get('placement').value ||
      cabana.get('scaling_factor').value ||
      cabana.get('location').value ||
      (cabana.get('attachments') as FormArray).controls.length ||
      (cabana.get('attachments_to_send') as FormArray).controls.length ||
      cabana.get('vicinity_map_send').value
    ) {
      return true;
    }
    return false;
  }

  private isLoungeValid(lounge: FormGroup) {
    if (
      lounge.get('total_chairs').value ||
      lounge.get('total_rows').value ||
      lounge.get('placement').value ||
      lounge.get('scaling_factor').value ||
      lounge.get('location').value ||
      (lounge.get('attachments') as FormArray).controls.length ||
      (lounge.get('attachments_to_send') as FormArray).controls.length ||
      lounge.get('vicinity_map_send').value
    ) {
      return true;
    }
    return false;
  }

  private createNewCabanaApi(cabanaValue, beachPoolId?) {
    const uploadData = new FormData();
    uploadData.append('beach_pool_id', beachPoolId ? beachPoolId : cabanaValue.beach_pool_id);
    if (cabanaValue.location) {
      uploadData.append('location', cabanaValue.location);
    }
    uploadData.append('total_rows', cabanaValue.total_rows ? cabanaValue.total_rows : '');
    uploadData.append('total_cabanas', cabanaValue.total_cabanas ? cabanaValue.total_cabanas : '');
    uploadData.append(
      'scaling_factor',
      cabanaValue.scaling_factor ? cabanaValue.scaling_factor : '',
    );
    uploadData.append('placement', cabanaValue.placement ? cabanaValue.placement : '');
    cabanaValue.attachments_to_send.forEach((data) => {
      uploadData.append('attachments[]', data);
    });

    if (cabanaValue.vicinity_map_send) {
      uploadData.append('vicinity_map', cabanaValue.vicinity_map_send);
    }

    // if (this.cabanaBeachPhoto || this.cabanaPoolPhoto) {
    //   hasValue = true;
    //   uploadData.append('attachments[]', this.cabanaPoolPhoto ? this.cabanaPoolPhoto : this.cabanaBeachPhoto);
    // }
    return this.resortService.createCabana(uploadData);
  }
  private updateCabanaApi(oldCabana, cabana, isBeach = false) {
    let cabanaValue = cabana.value;
    const originalCabana = this.getCabanaById(cabanaValue.id, cabanaValue.beach_pool_id, isBeach);

    let tempUpdateData = new FormData();
    tempUpdateData.append(
      'total_cabanas',
      cabanaValue.total_cabanas ? cabanaValue.total_cabanas : '',
    );
    tempUpdateData.append(
      'scaling_factor',
      cabanaValue.scaling_factor ? cabanaValue.scaling_factor : '',
    );
    tempUpdateData.append('total_rows', cabanaValue.total_rows ? cabanaValue.total_rows : '');
    tempUpdateData.append('placement', cabanaValue.placement ? cabanaValue.placement : '');

    oldCabana.location || cabanaValue.location
      ? tempUpdateData.append('location', cabanaValue.location)
      : null;

    // tempUpdateData.append("location", cabanaValue.location ? cabanaValue.location : '');

    cabanaValue.attachments_to_send.forEach((data) => {
      tempUpdateData.append('attachments[]', data);
    });

    if (cabanaValue.vicinity_map_send) {
      tempUpdateData.append('vicinity_map', cabanaValue.vicinity_map_send);
    }

    if (
      (cabanaValue.location && cabanaValue.location !== originalCabana['location']) ||
      cabanaValue.total_cabanas !== originalCabana['total_cabanas'] ||
      cabanaValue.scaling_factor !== originalCabana['scaling_factor'] ||
      cabanaValue.total_rows !== originalCabana['total_rows'] ||
      cabanaValue.placement !== originalCabana['placement'] ||
      cabanaValue.attachments_to_send.length > 0 ||
      cabanaValue.vicinity_map_send
    ) {
      return this.resortService.updateCabana(cabanaValue.id, tempUpdateData);

      // .subscribe(res => {
      //   for (let i = (cabanaValue.attachments_to_send.length); i > 0; i--) {
      //     (cabana.get('attachments') as FormArray).push(new FormControl(res.data.attachments[res.data.attachments.length - i].path));
      //   }

      //   let cabanaData = res.data;
      //   cabanaData.attachments_to_send = [];
      //   cabanaData.previewLocalAttachements = [];
      //   (cabana.get('attachments_to_send') as FormArray).reset();
      //   (cabana.get('previewLocalAttachements') as FormArray).clear();
      //   cabana.markAsPristine();

      // this.updateCabanaDataLocally(cabanaData, isBeach);

      // });
    } else {
      return null;
    }
  }
  private createNewLoungeApi(loungeValue, beachPoolId?) {
    const uploadData = new FormData();
    uploadData.append('beach_pool_id', beachPoolId ? beachPoolId : loungeValue.beach_pool_id);
    if (loungeValue.location) {
      uploadData.append('location', loungeValue.location);
    }
    uploadData.append('total_rows', loungeValue.total_rows ? loungeValue.total_rows : '');
    uploadData.append('total_chairs', loungeValue.total_chairs ? loungeValue.total_chairs : '');
    uploadData.append(
      'scaling_factor',
      loungeValue.scaling_factor ? loungeValue.scaling_factor : '',
    );
    uploadData.append('placement', loungeValue.placement ? loungeValue.placement : '');
    if (loungeValue.vicinity_map_send) {
      uploadData.append('vicinity_map', loungeValue.vicinity_map_send);
    }

    loungeValue.attachments_to_send.forEach((data) => {
      uploadData.append('attachments[]', data);
    });

    // if (this.cabanaBeachPhoto || this.cabanaPoolPhoto) {
    //   hasValue = true;
    //   uploadData.append('attachments[]', this.cabanaPoolPhoto ? this.cabanaPoolPhoto : this.cabanaBeachPhoto);
    // }
    return this.resortService.createLounge(uploadData);
  }
  private updateLoungeApi(oldLounge, lounge, isBeach = false) {
    let loungeValue = lounge.value;
    const originalLounge = this.getLoungeById(loungeValue.id, loungeValue.beach_pool_id, isBeach);
    // let attachementToSend=
    let tempUpdateData = new FormData();
    tempUpdateData.append('total_chairs', loungeValue.total_chairs ? loungeValue.total_chairs : '');
    tempUpdateData.append(
      'scaling_factor',
      loungeValue.scaling_factor ? loungeValue.scaling_factor : '',
    );
    tempUpdateData.append('total_rows', loungeValue.total_rows ? loungeValue.total_rows : '');
    tempUpdateData.append('placement', loungeValue.placement ? loungeValue.placement : '');

    oldLounge.location || loungeValue.location
      ? tempUpdateData.append('location', loungeValue.location)
      : null;
    loungeValue.attachments_to_send.forEach((data) => {
      tempUpdateData.append('attachments[]', data);
    });
    if (loungeValue.vicinity_map_send) {
      tempUpdateData.append('vicinity_map', loungeValue.vicinity_map_send);
    }

    if (
      (loungeValue.location && loungeValue.location !== originalLounge['location']) ||
      loungeValue.total_chairs !== originalLounge['total_chairs'] ||
      loungeValue.scaling_factor !== originalLounge['scaling_factor'] ||
      loungeValue.total_rows !== originalLounge['total_rows'] ||
      loungeValue.placement !== originalLounge['placement'] ||
      loungeValue.attachments_to_send.length > 0 ||
      loungeValue.vicinity_map_send
    ) {
      return this.resortService.updateLounge(loungeValue.id, tempUpdateData);
    } else {
      return null;
    }
  }
  doNothing() {}
  deleteLoungeAttachmentPreview(lounge, indexOfPreview) {
    (lounge.get('previewLocalAttachements') as FormArray).removeAt(indexOfPreview);
    (lounge.get('attachments_to_send') as FormArray).removeAt(indexOfPreview);
    this.cdRef.detectChanges();
  }
  deleteCabanaAttachmentPreview(cabana, indexOfPreview) {
    (cabana.get('previewLocalAttachements') as FormArray).removeAt(indexOfPreview);
    (cabana.get('attachments_to_send') as FormArray).removeAt(indexOfPreview);
    this.cdRef.detectChanges();
  }
  changeTimezone(timezone: string, target: FormGroup) {
    target.get('timezone').setValue(timezone);
    target.markAsDirty();
  }

  getTimezone() {
    return this.resort.timezone && this.resort.timezone.includes('/')
      ? this.resort.timezone
      : 'America/New_York';
  }

  resortAreas = [];

  toggleWhiteLabel($event) {
    this.isWhiteLabelEnabled = $event.target.checked;
  }
  setWhiteLabelData(whiteLabelData: FormGroup) {
    console.log('setWhiteLabelData', whiteLabelData);
    this.whiteLabelFormGroup = whiteLabelData;
  }
}
