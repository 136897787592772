import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/authentication/services/user.service';
import { ResortService } from '../../services/resort.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.scss']
})
export class TermAndConditionComponent implements OnInit {
  resort;
  resortActivated = false;
  termsAccepted = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private resortService: ResortService,
    private toasterService: ToastrService) { }

  ngOnInit() {
    this.resort = this.userService.getFullData();
    if (!(this.resort && !this.resort.resort.activated_at)) {
      this.router.navigate(['solay/resort']);
    }
  }
  backToResort() {
    if (this.resort.is_super_admin) {
      this.router.navigate([`solay/resort/${this.resort.resort.id}`]);
    } else {
      this.router.navigate(['solay/resort']);
    }
  }
  setTerms(event) {
    if (event.target.checked) {
      this.termsAccepted = true;
    } else {
      this.termsAccepted = false;
    }
  }
  acceptTerms() {
    if (this.termsAccepted) {
      this.resortService.updateResort(this.resort.resort.id, { activate: 1 }).subscribe(res => {
        if (res.code === 0) {
          this.toasterService.success('Thanks for accepting our terms and conditions of service');
          this.resortActivated = true;
          this.userService.updateResortField('activated_at', res.data.activated_at);
          if (this.resort.is_super_admin) {
            this.router.navigate([`solay/resort/${this.resort.resort.id}`], { state: { termsAccepted: true } });
          } else {
            this.router.navigate(['solay/resort'], { state: { termsAccepted: true } });
          }
        }
      });
    }
  }
}
