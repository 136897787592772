// default to production
let endpoint = 'https://api.solayapp.com';

// lookup api endpoint URL based on hostname
switch (window.location.hostname) {
  case 'resorts.solayapp.com':
    endpoint = 'https://api.solayapp.com';
    break;

  case 'staging-resorts.solayapp.com':
    endpoint = 'https://staging-api.solayapp.com';
    break;

  case 'develop-resorts.solayapp.com':
    endpoint = 'https://develop-api.solayapp.com';
    break;

  case 'localhost':
    endpoint = 'https://staging-api.solayapp.com';
    break;
}

// append api path
endpoint += '/api/v1';

export { endpoint };
