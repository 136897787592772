import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService, private router: Router, private toastr: ToastrService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (!(req.url.includes('login') || req.url.includes('register') || req.url.includes('passupdate') || req.url.includes('resetpass'))) {
            const token = this.authService.getTokenId();
            if (token) {
                const header = new HttpHeaders({
                    Authorization: `Bearer ${token}`
                });
                const cloneRequest = req.clone({ headers: header })
                return next.handle(cloneRequest);
            }
            this.toastr.error('Please login again');
            this.router.navigate(['login']);
        } else {
            return next.handle(req);
        }
    }

}