import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './authentication/services/user.service';
import { AuthenticationService } from './authentication/services/authentication.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'solay';
  isLoggedIn$: Observable<boolean>;
  constructor(private router: Router, private userService: UserService, private authService: AuthenticationService) {

  }
  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }
}
