import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/components/login/login.component';
import { ForgotPasswordComponent } from './authentication/components/forgot-password/forgot-password.component';
import { ResortComponent } from './solay/components/resort/resort.component';
import { AuthGuard } from './common/guards/auth.guard';
import { ResortListComponent } from './solay/components/resort-list/resort-list.component';
import { TermAndConditionComponent } from './solay/components/term-and-condition/term-and-condition.component';
import { NonAdminComponent } from './solay/components/non-admin/non-admin.component';
import { GuestBookingsComponent } from './solay/components/guest-bookings/guest-bookings.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'solay',
    children: [
      {
        path: '',
        redirectTo: 'resort',
        pathMatch: 'full'
      },
      {
        path: 'resort/:id',
        component: ResortComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'resort',
        component: ResortComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'resort-list',
        component: ResortListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'term-and-contition-service',
        component: TermAndConditionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'non-admin',
        component: NonAdminComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'guest-bookings',
        component: GuestBookingsComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '**',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
