import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ResortAreaEditorLoader } from '~components/resort-area-editor/resort-area-editor.loader';

@Component({
  selector: 'resort-area-editor',
  template: '<div #resortAreaEditorWrapper></div>',
  styleUrls: ['./resort-area-editor.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResortAreaEditorWrapper implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('resortAreaEditorWrapper', { static: false }) containerRef: ElementRef;

  @Input() public resort = null;

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    const reactDomImport = import('react-dom').then((ReactDOM) => ReactDOM);
    Promise.all([reactDomImport]).then(([ReactDOM]) => {
      ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
    });
  }

  private render() {
    const reactImport = import('react').then((React) => React);
    const reactDomImport = import('react-dom').then((ReactDOM) => ReactDOM);
    Promise.all([reactImport, reactDomImport]).then(([React, ReactDOM]) => {
      ReactDOM.render(
        <ResortAreaEditorLoader resort={this.resort} />,
        this.containerRef.nativeElement,
      );
    });
  }
}
