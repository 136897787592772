import * as React from 'react';

export const add = () => <img src="/assets/img/add_16px.png" alt="" aria-hidden />;
export const approve = () => <img src="/assets/img/approval_16px.png" alt="" aria-hidden />;
export const cabana = () => <img src="/assets/img/cabana-icon.png" alt="" aria-hidden />;
export const chair = () => <img src="/assets/img/chair-icon.png" alt="" aria-hidden />;
export const change = () => <img src="/assets/img/plus_and_minus_16px.png" alt="" aria-hidden />;
export const debug = () => <img src="/assets/img/help_16px.png" alt="" aria-hidden />;
export const info = () => <img src="/assets/img/user_manual_16px.png" alt="" aria-hidden />;
export const lock = () => <img src="/assets/img/lock_16px.png" alt="" aria-hidden />;
export const remove = () => <img src="/assets/img/cancel_16px.png" alt="" aria-hidden />;
export const reset = () => <img src="/assets/img/reset_16px.png" alt="" aria-hidden />;
export const save = () => <img src="/assets/img/save_16px.png" alt="" aria-hidden />;
