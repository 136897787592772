import * as React from 'react';
import produce from 'immer';

import { ResortAreaItem } from '~common/model';
import { cabana, chair } from '~components/resort-area-editor/resort-area-editor.icons';

type Props = {
  editorMode: string;
  columns: number;
  rows: number;
  items: ResortAreaItem[];
  setItems: (resortItems: ResortAreaItem[]) => void;
};

export const ResortAreaEditorMap = ({ editorMode, columns, rows, items, setItems }: Props) => {
  // If this component is being modified changes may be necessary in
  // `components/ResortAreaMap.tsx` of the Ionic app as well.

  const columnsArray = [...Array(columns).keys()].map((index) => index + 1);
  const rowsArray = [...Array(rows).keys()].map((index) => index + 1);

  // Click handlers
  const handleCellClick = (x: number, y: number) => {
    if (editorMode === 'locked') {
      return window.alert(
        `ℹ The editor is currently locked. Select "Set Chairs" or "Set Cabanas" to add/remove items.`,
      );
    }

    const item = items.find((item) => item.x === x && item.y === y);
    const isItem = item && item.type;

    if (!isItem) {
      setItems(
        produce(items, (draft: any) => {
          draft.push({
            id: getNextId(draft),
            type: getItemType(editorMode),
            x: x,
            y: y,
          });
        }),
      );
    } else {
      setItems(
        produce(items, (draft: any) => {
          return draft.filter((item: ResortAreaItem) => item.x !== x || item.y !== y);
        }),
      );
    }
  };

  // Components
  const ResortAreaEditorMapItem = ({ x, y }) => {
    const item = items.find((item) => item.x === x && item.y === y);
    const type = item && item.type ? item.type : null;

    let icon = null;
    switch (type) {
      case 'cabana':
        icon = cabana();
        break;
      case 'chair':
        icon = chair();
        break;
      default:
        break;
    }

    return (
      <>
        {item && item.id ? <span>{item.id}</span> : null}
        {icon}
      </>
    );
  };

  // Helpers
  const getItemType = (editorMode: string) => {
    switch (editorMode) {
      case 'addCabana':
        return 'cabana';
      case 'addChair':
        return 'chair';
      default:
        return 'Error: item type not found!';
    }
  };

  const getNextId = (items: ResortAreaItem[]) => {
    if (items.length === 0) return 1;

    let id = undefined;
    for (let i = 2; id === undefined; i++) {
      if (!items.find(({ id }) => id === i)) {
        id = i;
      }
    }

    return id;
  };

  return (
    <table>
      <tbody>
        {rowsArray.map((row) => (
          <tr key={`row${row}`}>
            {columnsArray.map((column) => (
              <td key={`column${column}`}>
                <div>
                  <button onClick={() => handleCellClick(column, row)}>
                    <ResortAreaEditorMapItem x={column} y={row} />
                  </button>
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
