import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ResortService } from '../../services/resort.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/authentication/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-non-admin',
  templateUrl: './non-admin.component.html',
  styleUrls: ['./non-admin.component.scss']
})
export class NonAdminComponent implements OnInit {

  isCodeSuceed: boolean;
  codeToGuestForm: FormGroup;
  formInited: boolean;
  fakeArray = new Array(14);
  user;


  constructor(
    private resortService: ResortService,
    private toasterService: ToastrService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initUserData();
    this.initCodeToGuestForm();
    this.formInited = true;
  }
  initUserData() {
    this.user = this.userService.getFullData();
    if (!(this.user && this.user.is_staff)) {
      this.router.navigate(['solay/resort']);
    }
  }
  initCodeToGuestForm() {
    this.codeToGuestForm = new FormGroup({
      email: new FormControl(''),
      mobile_no: new FormControl(''),
      length_of_stay: new FormControl('')
    }, { validators: this.customeValidator });
  }
  changeDaysOfStay(value) {
    this.codeToGuestForm.get('length_of_stay').setValue(value);
  }
  customeValidator(codeToGuestFormGroup: any) {
    return (codeToGuestFormGroup.controls['mobile_no'.toString()].value ||
      codeToGuestFormGroup.controls['email'.toString()].value) ? null : { oneFieldRequired: true };
  }

  saveCodeToGuest() {
    if (this.codeToGuestForm.valid) {
      this.resortService.sendCode(this.codeToGuestForm.value).subscribe(res => {
        if (res.code === 0) {
          this.isCodeSuceed = true;
          this.codeToGuestForm.patchValue({ email: '', mobile_no: '' });
          this.toasterService.success('Code was successfully sent to guest...');
        } else {
          this.toasterService.error(res.message);
        }
      }, err => {
        this.toasterService.error(err.error.message);
      });
    } else if (this.codeToGuestForm.get('mobile_no').errors) {
      this.toasterService.warning('Provide 10 digits for mobile no');
    } else {
      this.toasterService.warning('Provide input for all required fileds');
    }
  }
}
