import { Component, OnInit, Input, ViewChild, Output, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { CanvasWhiteboardOptions, CanvasWhiteboardComponent } from 'ng2-canvas-whiteboard';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResortService } from '../services/resort.service';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/authentication/services/user.service';

@Component({
  selector: 'app-map-edit-modal',
  templateUrl: './map-edit-modal.component.html',
  styleUrls: ['./map-edit-modal.component.scss'],
})
export class MapEditModalComponent implements OnInit, AfterViewInit {
  @Input() public target;
  @Input() public imageExists: boolean;
  @Input() public isBeach: boolean;
  @Input() public type: string;
  @Input() public beachPoolId: any;
  @Output() dataUpdated: EventEmitter<any> = new EventEmitter();
  @Output() photoDeleted: EventEmitter<any> = new EventEmitter();

  @ViewChild('CanvasWhiteboard', { static: false }) CanvasWhiteboard: ElementRef;
  @ViewChild('CanvasWhiteboard', { static: false }) CanvasWhiteboardObject: CanvasWhiteboardComponent;
  @ViewChild('poolVicinityMap', { static: false }) loadNewPhoto: ElementRef;

  canvasOptions: CanvasWhiteboardOptions;
  isVicinityMapExists: boolean;
  overlaying = true;
  disableVisinityUpdateButton: boolean;
  vicinityThumbnail;
  uploadPanel: boolean;
  fileUploed: boolean;
  hasImage = false;
  modalTitle: string;
  imageHeight = 400;
  imageloaded = true;
  imageWidth = 400;
  area: string;

  constructor(public activeModal: NgbActiveModal, private resortService: ResortService, private userService: UserService) { }

  ngOnInit() {
    this.initEditor();
    this.initVicinityPool();
  }
  initEditor() {
    const strokeColor = (this.type === 'cabanaMap') ? 'rgb(4, 138, 73)' : 'rgb(255, 184, 77)';
    this.canvasOptions = {
      drawingEnabled: true,
      lineWidth: 5,
      strokeColor
    };
  }
  ngAfterViewInit() {
    if (!this.imageExists) {
      this.loadNewPhoto.nativeElement.click();
    }
  }
  initVicinityPool() {
    if (this.imageExists) {
      this.hasImage = true;
      this.isVicinityMapExists = true;
      this.overlaying = false;
      this.canvasOptions.drawingEnabled = false;
      switch (this.type) {
        case 'cabanaMap':
        case 'loungeMap': {
          console.log(this.target);

          this.area = (this.type === 'cabanaMap') ? 'Cabana' : 'Lounge chair';
          this.imageloaded = false;
          this.canvasOptions.imageUrl = this.target.vicinity_map;
          this.getImageDimensionFromUrl(this.target.vicinity_map).subscribe(res => {
            this.canvasOptions.aspectRatio = res.height / res.width;
            this.imageHeight = res.height;
            this.imageloaded = true;
          });
          this.modalTitle = 'Map';
          break;
        } case 'layout': {
          this.imageloaded = false;
          this.canvasOptions.imageUrl = this.target.layout;
          this.getImageDimensionFromUrl(this.target.layout).subscribe(res => {
            this.canvasOptions.aspectRatio = res.height / res.width;
            this.imageHeight = res.height;
            this.imageloaded = true;
            setTimeout(() => {
              this.imageHeight = this.CanvasWhiteboard['canvas'.toString()].nativeElement.height;
            }, 10);
          });
          this.modalTitle = 'Layout';
          break;
        } case 'resort': {
          console.log(this.target.resort_image);
          
          this.imageloaded = false;
          this.canvasOptions.imageUrl = this.target.resort_image;
          this.getImageDimensionFromUrl(this.target.resort_image).subscribe(res => {
            this.canvasOptions.aspectRatio = res.height / res.width;
            this.imageHeight = res.height;
            this.imageloaded = true;
            setTimeout(() => {
              this.imageHeight = this.CanvasWhiteboard['canvas'.toString()].nativeElement.height;
            }, 10);
          });
          this.modalTitle = 'Layout';
          break;
        }
        default:
          break;
      }
    }
  }
  removeOverlay() {
    this.overlaying = false;
  }
  saveCabanaOrLounge(canvasWhiteboard: any) {
    this.disableVisinityUpdateButton = true;
    // canvasWhiteboard.generateCanvasDataUrl((dataurl))
    // let data:{dataUrl,blob}={dataUrl:'',blob:''};
    let data;
    canvasWhiteboard.generateCanvasData((url:any) => {
      data={
        dataUrl:url
      }
    });
    canvasWhiteboard.generateCanvasBlob((blob:any) => {
      data={
        ...data,
        blob:blob
      }
      this.imageUpdated(data);
    });
  }
  clearCanvas(canvasWhiteboard: any) {
    canvasWhiteboard.clearCanvas();
  }
  undoCanvas(canvasWhiteboard: any) {
    canvasWhiteboard.undo();
  }
  uploadImage(event, canvasWhiteboard: any) {
    switch (this.type) {
      case 'cabanaMap':
      case 'loungeMap':
      case 'map': {
        this.hasImage = true;
        this.fileUploed = true;
        this.isVicinityMapExists = false;
        this.area = (this.type === 'cabanaMap') ? 'Cabana' : 'Lounge chair';
        if (event.target.files.length > 0) {
          const reader = new FileReader();
          const img = new Image();
          img.src = window.URL.createObjectURL(event.target.files[0]);
          reader.onload = () => {
            this.vicinityThumbnail = reader.result as string;
            setTimeout(() => {
              canvasWhiteboard.imageUrl = this.vicinityThumbnail;
              canvasWhiteboard.aspectRatio = (img.naturalHeight / img.naturalWidth);
              canvasWhiteboard.ngAfterViewInit();
              this.imageHeight = (this.CanvasWhiteboard['canvas'.toString()].nativeElement.height > 400)
                ? this.CanvasWhiteboard['canvas'.toString()].nativeElement.height : 400;
            }, 100);

            // this.imageHeight = this.CanvasWhiteboard['canvas'.toString()].nativeElement.height;
          };
          reader.readAsDataURL(event.target.files[0]);
          canvasWhiteboard.drawingEnabled = true;
          this.overlaying = true;
        }
        break;
      } case 'layout': {
        if (event.target.files.length > 0) {
          this.imageUpdated(event.target.files[0]);
        }
        break;
      } case 'resort': {
        if (event.target.files.length > 0) {
          // const formData = new FormData();
          // formData.append('resort_image', event.target.files[0]);
          // this.resortService.updateResort(this.target.id, formData).subscribe(res => {
          this.imageUpdated(event.target.files[0]);
          // });
        }
      }
      default:
        break;
    }
  }
  handleFileInput(files: FileList) {
    if (files.length === 0) {
      this.uploadPanel = true;
    }
  }
  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    if (this.uploadPanel && !this.imageExists && !this.fileUploed) {
      setTimeout(() => {
        if (!this.fileUploed) {
          this.activeModal.close();
        }
      }, 1000);
    }
  }
  deletePhoto() {
    switch (this.type) {
      case 'cabanaMap': {
        this.resortService.updateCabana(this.target.id, { vicinity_map_delete: 1 }).subscribe(res => {
          this.imageDeleted(res);
        });
        break;
      } case 'loungeMap': {
        this.resortService.updateLounge(this.target.id, { vicinity_map_delete: 1 }).subscribe(res => {
          this.imageDeleted(res);
        });
        break;
      } case 'map': {
        if (this.isBeach) {
          this.resortService.updateBeach(this.target.id, { vicinity_map_delete: 1 }).subscribe(res => {
            this.imageDeleted(res);
          });
        } else {
          this.resortService.updatePool(this.target.id, { vicinity_map_delete: 1 }).subscribe(res => {
            this.imageDeleted(res);
          });
        }
        break;
      } case 'layout': {
        if (this.isBeach) {
          this.resortService.updateBeach(this.target.id, { layout_delete: 1 }).subscribe(res => {
            this.imageDeleted(res);
          });
        } else {
          this.resortService.updatePool(this.target.id, { layout_delete: 1 }).subscribe(res => {
            this.imageDeleted(res);
          });
        }
        break;
      } case 'resort': {
        // this.resortService.updateResort(this.target.id, { resort_image_delete: 1 }).subscribe(res => {
        this.imageDeleted();
        // });
      }
      default: {
        break;
      }
    }
  }
  imageDeleted(res?) {
    // if (response.code === 0) {
    this.photoDeleted.emit({ imageDeleted: true });
    this.activeModal.close();
    // }
  }
  imageUpdated(data) {
    this.dataUpdated.emit(data);
    this.activeModal.close();
  }
  getImageDimensionFromUrl(image): Observable<any> {
    return new Observable(observer => {
      const img = new Image();
      img.onload = (event) => {
        const loadedImage: any = event.currentTarget;
        observer.next({ height: loadedImage.height, width: loadedImage.width });
        observer.complete();
      };
      img.src = image;
    });
  }
}
